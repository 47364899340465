import React from 'react';
import IntlMessages from 'util/IntlMessages';
import config from '../../config';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block"><IntlMessages id="footer.copright"/> &copy; {config.coprightYear}</span>
      </footer>
    );
  }
;

export default Footer;
