import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userForgotMyPassword
} from 'actions/Auth';

class ForgotMyPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      emailErrorText:'', 

    }
  }

  // componentDidUpdate() {
  //   if (this.props.showMessage) {
  //     setTimeout(() => {
  //       this.props.hideMessage();
  //     }, 100);
  //   }
  //   if (this.props.authUser !== null) {
  //     this.props.history.push('/');
  //   }
  // }

  validate = ()=>{
    let isError = false;
    const errors = {};
   
    if(this.state.email.length < 1){
      isError = true;
      errors.emailErrorText = "Mail Adresi veya T.C. Kimlik No alanı zorunludur."
    }
    if(isError){
      this.setState({
        ...this.state,
        ...errors
      })
    }

    return isError;
  }

  render() {
    const {
      email
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="BBS">
            {
                this.props.locale.locale ==="en" ? 
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz_en.png")} width="250px" alt="IIS" title="IIS"/> 
                :
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>
            }
                       </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="signIn.forgotMyPassword"/></h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email"/>}
                    fullWidth
                    onChange={(event) => this.setState({email: event.target.value})}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    error= {this.state.emailErrorText === "" ? false : true }   
                    helperText={this.state.emailErrorText}
                  />
                  

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => {
                  

                      const err = this.validate();
                      if(!err)
                      {
                        this.props.showAuthLoader();
                        this.props.userForgotMyPassword({email,lang:this.props.locale.locale});
                      }
                   
                    }} variant="contained" color="primary">
                      <IntlMessages id="signIn.forgotMyPassword"/>
                    </Button>

                    <Link to="/signup">
                      <IntlMessages id="signIn.signUp"/>
                    </Link>
                  
                    
                  </div>

                  <div className="app-social-block my-1 my-sm-3">
                   
                    
                  </div>

                </fieldset>
              </form>
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({auth,settings}) => {
  const {loader, alertMessage, showMessage, authUser,userName} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser,userName,locale}
};

export default connect(mapStateToProps, {
  userForgotMyPassword,
  hideMessage,
  showAuthLoader
})(ForgotMyPassword);
