import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    FORGOT_MY_PASSWORD_USER,
    RESET_PASSWORD_USER,
    ACTIVATION_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "actions/Auth";

import { postLoginUserApi , postCreateUserApi,postForgotMyPasswordUserApi , postResetPasswordUserApi, postActivationUserApi, postLoginEdevletUserApi } from "../api/auth";
//import config from "../config";
import {envConfig} from '../environment';
import IntlMessages from 'util/IntlMessages';

//import { putTaleProcessUpdateStatusByTaleProcessIdApi } from "../api/taleProcess";
import config from "../config";
import SimpleCrypto from "simple-crypto-js";
import React  from 'react';

const simpleCrypto = new SimpleCrypto(config.sk);

const createUserWithEmailPasswordRequest = async (user) =>
  {
 
   const result =  await postCreateUserApi(user)
    //.then(user => user)
    .catch(error => error);

    const returnValue = {status : result.status ,userId:result.id , message : result.message};
    return returnValue;
  }  

const signInUserWithEmailPasswordRequest = async (email, password,isEdevlet,lang) =>
    {
      
        const user = { email, password,lang};

       let result =  {};
        
        if(isEdevlet)
             result =  await postLoginEdevletUserApi(user).catch(error => error);
        else
             result =  await postLoginUserApi(user).catch(error => error);

        const authUser = result.user;
        const returnValue = {status : result.status ,user:authUser , message : result.message ,token : result.token};
        return returnValue;
    }
    

const signOutRequest = async () =>
 {   
//      await  auth.signOut()
//         .then(authUser => authUser)
//         .catch(error => error);
 //   return undefined;
    }


function* createUserWithEmailPassword({payload}) {
    const user = payload;
   
    try {
        if(user.password===user.passwordReply){
         
                            delete user["passwordReply"];
                        const signUpUser = yield call(createUserWithEmailPasswordRequest, user);
                
                        if (!signUpUser.status) {
                            yield put(showAuthMessage(signUpUser.message));


                           



                        } else {
                            yield put(showAuthMessage(signUpUser.message));
                            setTimeout(() => {
                                window.location.href = envConfig.mainPageUrl; 
                              }, 2000);
                        }
        

            
        }
        else{
            signInUser.message =  <IntlMessages id="user.changePassword.errorMessage"/>
            yield put(showAuthMessage(signInUser.message));
        }

       
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


const forgotMyPasswordUserWithEmailRequest = async (email) =>
    {
        // await  auth.signInWithEmailAndPassword(email, password)
        // .then(authUser => authUser)
        // .catch(error => error);
      
      //  //console.log("email:",email)

       const result =  await postForgotMyPasswordUserApi(email)
        //.then(user => user)
        .catch(error => error);


        const authUser = result.user;
        const returnValue = {status : result.status ,user:authUser , message : result.message};
        return returnValue;
    }

function* forgotMyPasswordUserWithEmail({payload}) {
    const {email,lang} = payload;

    try {
        const signInUser = yield call(forgotMyPasswordUserWithEmailRequest, {email,lang} );
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
           
          //  this.props.history.push("/signin");

          setTimeout(() => {
            window.location.href = envConfig.mainPageUrl; 
          }, 2000);

         
        } else {
            
        
           
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password,isEdevlet,lang} = payload;

    try {
        const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password,isEdevlet,lang);
        if (signInUser.message) {
            yield put(showAuthMessage(signInUser.message));
        } else {
            

         //   localStorage.setItem('userId', signInUser.user.userId);
        //    localStorage.setItem('userName',  signInUser.user.name + " " + signInUser.user.surname);
            localStorage.setItem("user", simpleCrypto.encryptObject(signInUser.user));
            localStorage.setItem("userToken", signInUser.token);
            yield put(userSignInSuccess(signInUser.user));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
      //  //console.log("ÇIkış sagasa geldi. User :",signOutUser)
        if (signOutUser === undefined) {
        //    localStorage.removeItem('userId');
           localStorage.removeItem('userToken');
           localStorage.removeItem("user");
         
          
            yield put(userSignOutSuccess(signOutUser));

        //    window.location.href = envConfig.mainPageUrl;
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}


export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        
        fork(signInUser),
        fork(createUserAccount),
        fork(signOutUser),
        fork(forgotMyPasswordUser),
        fork(resetPasswordUser),
        fork(activationUser)
    
    ]);
}

export function* forgotMyPasswordUser() {
    yield takeEvery(FORGOT_MY_PASSWORD_USER, forgotMyPasswordUserWithEmail);
}


function* userResetPasswordUserWithEmail({payload}) {
  //  const {email, password,passwordReply} = payload;
    let signInUser = {};
    const resetPaswordObject = {
        email : payload.email,
        password: payload.password,
        reminderCode : payload.reminderCode,
        lang:payload.lang
    }
    try {
        if(payload.password===payload.passwordReply){

           

            signInUser = yield call(userResetPasswordUserWithEmailRequest, resetPaswordObject);
            if (!signInUser.status) {
                yield put(showAuthMessage(signInUser.message));
            } else {
           //     yield put(userSignInSuccess(signInUser.user));

                setTimeout(() => {
                    window.location.href = envConfig.mainPageUrl;
                   }, 1000);
        
            }
        }
        else{
            signInUser.message =  <IntlMessages id="user.changePassword.errorMessage"/>
          //  //console.log("******",signInUser);
            yield put(showAuthMessage(signInUser.message));
        }
        
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


const userResetPasswordUserWithEmailRequest = async (resetPaswordObject) =>
    {
       
      
      
       const result =  await postResetPasswordUserApi(resetPaswordObject)
        .catch(error => error);
        return result;
    }

    export function* resetPasswordUser() {
        yield takeEvery(RESET_PASSWORD_USER, userResetPasswordUserWithEmail);
    }
    

    
    export function* activationUser() {
        yield takeEvery(ACTIVATION_USER, userActivationWithEmailAndActivationCode);
    }

    function* userActivationWithEmailAndActivationCode({payload}) {
        //  const {email, password,passwordReply} = payload;
          let result = {};
          const activedObject = {
              email : payload.email,
              activationCode : payload.activationCode,
              lang : payload.lang
          }
          try {
            

                   result = yield call(userActivationWithEmailAndActivationCodeRequest, activedObject);
                  if (!result.status) {
                       yield put(showAuthMessage(result.message));
                  } else {
                       yield put(showAuthMessage(result.message));
                //    yield put(userSignInSuccess(result.user));
      
                       setTimeout(() => {
                           window.location.href = envConfig.mainPageUrl;
                          }, 1000);
              
                   }
             
              
          } catch (error) {
              yield put(showAuthMessage(error));
          }
      }

      const userActivationWithEmailAndActivationCodeRequest = async (activedObject) =>
    {
       
      
      
       const result =  await postActivationUserApi(activedObject)
        .catch(error => error);
        return result;
    }