import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  userResetPassword,

} from 'actions/Auth';

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      passwordReply: '',
      emailErrorText:'',
      passwordErrorText:'',
      passwordReplyErrorText:''   
    } 
  }

  validate = ()=>{
    let isError = false;
    const errors = {};
   
    if(this.state.email.length < 1){
      isError = true;
      errors.emailErrorText = "Mail Adresi alanı zorunludur."
    }
    if(this.state.password.length < 6){
      isError = true;
      errors.passwordErrorText = "Şifre alanı zorunludur. En az 6 karakter."
    }
    if(this.state.password.length < 6){
      isError = true;
      errors.passwordReplyErrorText = "Şifre Tekrar alanı zorunludur. En az 6 karakter."
    }
    if(isError){
      this.setState({
        ...this.state,
        ...errors
      })
    }

    return isError;
  }

  render() {

    const {
      email,
      password,
      passwordReply
    } = this.state;

    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString); 
    const reminderCode = params.get('rc');

    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Tümak - I">
            {
                this.props.locale.locale ==="en" ? 
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz_en.png")} width="250px" alt="IIS" title="IIS"/> 
                :
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>
            }
                    </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1><IntlMessages id="resetPassword.resetPassword"/></h1>
            </div>

            <div className="mb-4">
             
            </div>

            <div className="app-login-form">
              <form method="post" action="/">
                <TextField
                  type="email"
                  onChange={(event) => this.setState({email: event.target.value, emailErrorText:""})}
                  label={<IntlMessages id="appModule.email"/>}
                  fullWidth
                  error= {this.state.emailErrorText === "" ? false : true }   
                  helperText={this.state.emailErrorText}
                  margin="normal"
                  className="mt-0 mb-2"
                />
                  <TextField
                  type="password"
                  onChange={(event) => this.setState({password: event.target.value,passwordErrorText:""})}
                  label={<IntlMessages id="resetPassword.password"/>}
                  fullWidth
                  error= {this.state.passwordErrorText === "" ? false : true }   
                  helperText={this.state.passwordErrorText}
                  margin="normal"
                  className="mt-0 mb-2"
                />
                
                <TextField
                  type="password"
                  onChange={(event) => this.setState({passwordReply: event.target.value, passwordReplyErrorText:""})}
                  label={<IntlMessages id="resetPassword.passwordReply"/>}
                  fullWidth
                  error= {this.state.passwordReplyErrorText === "" ? false : true }   
                  helperText={this.state.passwordReplyErrorText}
                  margin="normal"
                  className="mt-0 mb-2"
                />
                
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" onClick={() => {
                    const err = this.validate();
                    if(!err)
                    {
                      this.props.showAuthLoader();
                      this.props.userResetPassword({email, password,passwordReply,reminderCode,lang:this.props.locale.locale});
                    }
                 
                  }} color="primary">
                    <IntlMessages
                      id="resetPassword.resetPasswordButton"/>
                  </Button> 
                </div>
                <div className="app-social-block my-1 my-sm-3">
              
                </div> 

              </form>
            </div>
          </div>

        </div>

        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    )
  }
}

const mapStateToProps = ({auth,settings}) => {
  const {loader, alertMessage, showMessage, authUser,userName} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser,userName,locale}
};
export default connect(mapStateToProps, {
  userResetPassword,
  hideMessage,
  showAuthLoader,
})(ResetPassword);
