
import {
            UPDATE_USER 
} from 'constants/ActionTypes';

export const userUpdate = (user) => {
    return {
        type: UPDATE_USER,
        payload: user
    };
};