import config from "./config";

let envConfig={};
 
if(process.env.REACT_APP_ENV ==="development"){
    envConfig = config.devConfig;
}
else if(process.env.REACT_APP_ENV ==="test"){
    envConfig = config.testConfig;
}
else if(process.env.REACT_APP_ENV ==="production"){
    envConfig = config.prodConfig;
}

 
export  {envConfig}; 