import React,{useState} from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from 'util/IntlMessages';


function KVKKDialog(props) {



  return (
  
    <Dialog open={props.open} fullWidth maxWidth="lg" onClose={props.onClose}>
          <DialogTitle>
          KVKK Aydınlatma Metni 
          </DialogTitle>
          <DialogContent>

          <div class="page-content content">


<p class="western" align="center"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”)</b></span></span></span></p>
<p class="western" align="center"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>USUL VE ESASLARI UYARINCA KİŞİSEL VERİLERİNİZİN KORUNMASI</b></span></span></span></p>
<p class="western" align="center"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>HAKKINDA AYDINLATMA METNİ</b></span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Atatürk Kültür, Dil ve Tarih Yüksek Kurumu(AYK) (Kurum) olarak kişisel verilerinizin güvenliği hususuna azami hassasiyet gösterilmektedir. Bu bilinçle, kurum ile ilişkili tüm şahıslara ait her türlü kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVK Kanunu”)’na uygun olarak işlenerek, muhafaza edilmesine büyük önem verilmektedir. Bu sorumluluğumuzun tam idraki ile KVK Kanunu’nda tanımlı şekli ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda izah edildiği surette ve mevzuat tarafından emredilen sınırlar çerçevesinde işlenilmektedir.</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>a) Veri Sorumlusu ve Temsilcisi</b></span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak </span></span></span><span ><span style={{fontStyle:10}}>Ziyabey Cad. Nu:19 06520 Balgat – Çankaya / ANKARA </span></span><span ><span style={{fontStyle:10}}><span lang="tr-TR">adresinde mukim AYK tarafından aşağıda açıklanan kapsamda işlenebilecektir. 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) gereğince; kişinin kimlik, iletişim, ikametgah, özgeçmiş gibi bilgileri, finansal veri, özel nitelikli kişisel verileri, çalışma verisi, görsel ve işitsel veri, Web Sitesi Kullanım Verileri ile kurumumuzla ticari hizmet ilişkisi kapsamında edinilen, ilgili kişiyi//kurumu/şirketi tanıtan her türlü bilgi ve belge, kişisel veri / özel nitelikli kişisel veri kapsamındadır.</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>b) Kişisel Verilerin Hangi Amaçla İşleneceği</b></span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Toplanan kişisel verileriniz, Kurumumuzun insan kaynakları politikalarının yürütülmesinin temini; Kurumumuzun ve Kurumumuz ile iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini; Kurumumuzun ticari ve iş stratejilerinin belirlenmesi ve uygulanması amaçlarıyla faaliyetlerimizi yürütmek çerçevesinde 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Başlıklar halinde ayrıntılı incelendiğinde;</span></span></span></p>
<p align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurumumuzun ve kurumumuzla iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini amacı doğrultusunda;</span></span></span></p>
<ul>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurumun hukuk işlerinin takibi,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum faaliyetlerinin kurum prosedürleri ve/veya ilgili mevzuata uygun olarak yürütülmesinin temini için gerekli operasyonel faaliyetlerinin planlanması ve icrası,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Ziyaretçi kayıtlarının oluşturulması ve takibi,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum yerleşkeleri ve/veya tesislerinin güvenliğinin temini,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum demirbaşlarının ve/veya kaynaklarının güvenliğinin temini,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum operasyonlarının güvenliğinin temini,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Acil durum yönetimi süreçlerinin planlanması ve icrası,</span></span></span></li>
</ul>
<p align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurumumuzun kanun çerçevesinde yürüttüğü faaliyetlere ilişkin stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda;</span></span></span></p>
<ul>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurumumuz tarafından yürütülen bilimsel projeler ve araştırmalar, eğitim ve araştırma bursları faaliyetleri, iletişim ve sosyal sorumluluk aktiviteleri, satın alma işlemleri, kalite süreçleri ve operasyonları,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum içi sistem ve uygulama yönetimi operasyonları</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kurum dışından gerçekleştirilen eğitim faaliyetlerinin planlanması ve/veya icrası,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Paydaşlar ve/veya tedarikçilerle olan ilişkilerin yönetimi gibi amaçlar olarak sıralanabilmektedir.</span></span></span></li>
</ul>
<p align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Borçlar Kanunu, Bankacılık Kanunu, Sermaye Piyasası Kanunu, İş Kanunu, Sosyal Sigortalar ve Genel Sağlık Sigortası Kanunu, Sosyal Güvenlik Kurumu Kanunu, Sosyal Sigortalar İşlemleri Yönetmeliği, İş Sağlığı ve Güvenliği Kanunu, İş Sağlığı ve Güvenliği Hizmeti Yönetmeliği, Gelir Vergisi Kanunu, Kişisel Verilerin Korunması Kanunu, Kişisel Sağlık Verilerinin İşlenmesi ve Mahremiyetinin Sağlanması Hakkında Yönetmelik gibi yasal düzenlemelerde yer alan işveren yükümlülükleri ve bilgilerin yasal süreler dahilinde saklanmasına ilişkin düzenlemeler ile Bankacılık Düzenleme ve Denetleme Kurumu, Sermaye Piyasası Kurumu, Sosyal Sigortalar Kurumu, Gelir İdaresi Başkanlığı, Kişisel Verileri Koruma Kurumu gibi yasal otoritelerce öngörülen bilgi saklama, raporlama, </span></span></span><span ><span style={{fontStyle:10}}>bilgilendirme yükümlülüklerinin yerine getirilmesi amacı ile kişisel veriler, özel nitelikli kişisel veriler, sağlık verileri Veri Sorumlusu olarak Kurumumuzca sistemlerimize kaydedilmekte, depolanmakta, korunmakta, sınıflandırılmakta, güncellenmekte ve yasal sınırlar dahilinde resmi makamlar ve 3. kişiler ile paylaşılabilmekte veya “Kişisel Verilerin Korunması Kanunu”nda işlenmelerini gerektiren amaç çerçevesinde ve bu amaçla bağlantılı, sınırlı ve ölçülü şekilde, tarafımıza bildirdiğiniz veya bildirildiği şekliyle kişisel verilerin doğruluğunu ve en güncel halini koruyarak işlenebilmektedir.<br />
</span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><b>c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği </b></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}>Toplanan kişisel verileriniz; Kurmumuzun ve Kurumumuzla iş ilişkisi içerisinde olan kişilerin/kurumların/şirketlerin hukuki ve ticari güvenliğinin temini; Kurumumuzun iş stratejilerinin belirlenmesi ve uygulanması amaçlarıyla; iş ortaklarımıza, tedarikçilerimize, hissedarlarımıza, iştiraklerimize, paydaşlarımıza kanunen yetkili kamu kurumları ve özel kişilere, faaliyetlerimizi yürütmek çerçevesinde 6698 sayılı Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde sınırlı olarak aktarılabilecektir. </span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}>Bununla birlikte kişisel verileriniz zaman zaman belli faaliyetleri gerçekleştirebilmemiz için o alanda uzman firmalara veya kullandığımız uygulamaların sunucularına aktarılabilmektedir. Örneğin;</span></span></p>
<ul>
<li><span ><span style={{fontStyle:10}}>Kitap Satış Mağazalarımız ve E-mağazalarımızda satışa sunulan basılı yayınlarımızı size ulaştırabilmek adına verilerinizi kargo şirketlerine, taşıyıcı şirketlere aktarabilmekteyiz.</span></span></li>
<li><span ><span style={{fontStyle:10}}>SMS gönderim listemizde yer almanız halinde toplu SMS veya toplu e-posta listemizde yer almanız halinde toplu e-posta gönderen firmalara yalnız söz konusu faaliyetin gerçekleştirilmesi için gerekli olan verileriniz aktarılabilmektedir.</span></span></li>
<li><span ><span style={{fontStyle:10}}>Faaliyetlerimiz çerçevesinde ürettiğimiz, elde ettiğimiz ve edindiğimiz bilgi, belge, dosya vb. kayıtların muhafazası hizmetini sağlayan uygulamaları ve kurum bilişim sistemleri dahilindeki sunuculara kaydedilebilmektedir.</span></span></li>
<li><span ><span style={{fontStyle:10}}>Hukuki talepleri yerine getirme yükümlülüğümüz çerçevesinde ilgili kurumlarla paylaşılabilmektedir.</span></span></li>
</ul>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><b>ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</b></span></span></p>
<p class="western" align="justify"><a name="_GoBack"></a> <span ><span style={{fontStyle:10}}>Kişisel verileriniz başvurunuz sırasında ve/veya sonrasında yazılı ve/veya sözlü olarak ve/veya elektronik ortamda tarafımıza iletmiş olduğunuz, sağlık bilgileri de dahil olmak üzere özel nitelikli kişisel verilerinizi de içeren her türlü bilgi ve belgeyi ifade etmekte olup, bu bilgiler fiziki ve dijital ortamda saklanmaktadır. Kişisel veriler, ilgili mevzuat, sözleşme, talep, ticari teamül ve dürüstlük kurallarına dayalı olarak ortaya çıkan ve icra edilen hukuki sebepler çerçevesinde işlenmektedir. Kişisel verileriniz, KVKK tarafından öngörülen temel ilkelere uygun olarak, KVKK’nın 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında işbu Aydınlatma Metni’nin (b) ve (c) maddelerinde belirtilen amaçlarla gerçek ve tüzel kişiler ile aşağıda yer alan amaçlarla işlenebilmekte ve aktarılabilmektedir.</span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><b>d) Kişisel Verilerinizin İşlenme süresi</b></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}>Kişisel verileriniz başvurunuz sırasında ve/veya sonrasında yazılı ve/veya sözlü olarak ve/veya elektronik ortamda KVKK’ya uygun olarak, işbu Aydınlatma Metni’nde belirtilen amaçlarla işlenmiş olan kişisel verileriniz, KVKK md. 7/f.1.’e göre işlenmesi gerektiren amaç ortadan kalktığında ve/veya mevzuat uyarınca verilerinizi işlememiz için zorunlu kılındığımız zamanaşımı süreleri dolduğunda, kişisel verileriniz tarafımızca silinecek, yok edilecek veya anonimleştirerek kullanılmaya devam edilecektir.</span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><b>e) KVKK uyarınca Kurumumuzun Kişisel Verilerinizi Açık Rızanız Olmaksızın İşleyebileceği Haller:</b></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}>KVKK’nın 5. maddesi uyarınca, aşağıdaki hallerde kurumumuz, açık rızanız aranmaksızın yukarıda belirtilen ve kanuna uygun olarak almış olduğu kişisel verilerinizi işleyebilir:</span></span></p>
<ul>
<li><span ><span style={{fontStyle:10}}>Kanunlarda açıkça öngörüldüğü hallerde,</span></span></li>
<li><span ><span style={{fontStyle:10}}>Fiili imkânsızlık nedeniyle veri sahibi olarak rızanızı açıklayamayacak durumda olmanız veya rızanıza hukuki geçerlilik tanınmayan hallerde kendinizin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için kişisel verinizin işlenmesinin zorunlu olması,</span></span></li>
<li><span ><span style={{fontStyle:10}}>AYK ve kuruluşuna dahil olan kurumları, diğer gerçek ve/veya tüzel kişiler ile akdettiğiniz bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,</span></span></li>
<li><span ><span style={{fontStyle:10}}>Kurumumuzun bir hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</span></span></li>
<li><span ><span style={{fontStyle:10}}>Kişisel verinizin tarafınızca alenileştirilmiş olması,</span></span></li>
<li><span ><span style={{fontStyle:10}}>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</span></span></li>
<li><span ><span style={{fontStyle:10}}>Sahip olduğunuz temel hak ve özgürlüklerinize zarar vermemek kaydıyla, kurumumuzun meşru menfaatleri için veri işlenmesinin zorunlu olması.</span></span></li>
</ul>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><b>f)Kişisel Veri Sahibinin 6698 sayılı Kanun’un 11. Maddesinde Sayılan Hakları</b></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}>Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi bize iletmeniz durumunda, Kurumumuz, talebin niteliğine göre talebi en kısa sürede ve/veya en geç otuz gün içinde sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyet gerektirmesi hâlinde, Kurumumuz tarafından Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücret alınacaktır. Bu kapsamda kişisel veri sahipleri, Kurumumuzdan;</span></span></p>
<ul>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kişisel veri işlenip işlenmediğini öğrenme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</span></span></span></li>
<li><span ><span style={{fontStyle:10}}><span lang="tr-TR">Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.</span></span></span></li>
</ul>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">6698 sayılı Kanun’un 13. maddesinin 1. fıkrası gereğince yukarıda belirtilen haklarınızı kullanmakla ilgili talebinizi ve bununla beraber, kişisel verilerinizle ilgili değişiklik ve/veya güncellemeleri her zaman “</span></span></span><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>yazılı</b></span></span></span><span ><span style={{fontStyle:10}}><span lang="tr-TR">” olarak kurumumuzun </span></span></span><span ><span style={{fontStyle:10}}>Ziyabey Cad. Nu:19 06520 Balgat – Çankaya / ANKARA</span></span><span ><span style={{fontStyle:10}}><span lang="tr-TR"> adresine kimliğinizi tespit edici belgeler ile bizzat elden ya da posta ile imzalı olarak iletmeniz gerekmektedir. Kurumumuz tarafından talebinizin mahiyetine ve başvuru yönteminize göre Kurum tarafından başvurunun size ait olup olmadığının belirlenmesi ve böylece haklarınızı koruyabilmek amacıyla ek doğrulamalar (kayıtlı telefonunuza mesaj gönderilmesi, aranmanız gibi) istenebilir. Kurumda kayıtlı başka bir iletişim yöntemini kullanarak size ulaşabilir ve başvurunun size ait olup olmadığının teyidi istenebilmektedir. </span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin Kurum için ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından Veri Sorumlusuna Başvuru Usül ve Esasları Hakkında Tebliği’de belirlenen tarifedeki ücret alınabilir.</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ve kullanmak istediğiniz hakkınıza yönelik açıklamalarınızla birlikte talebinizi 6698 sayılı Kanun’un 11. maddesinde belirtilen hangi hakkınızın kullanımına ilişkin olduğunu da belirterek bizlere iletmeniz; talebinize ilişkin başvurunuzun daha hızlı ve etkili bir şekilde cevaplandırılmasını sağlayacaktır.</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR"><b>İLETİŞİM BİLGİLERİ</b></span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Atatürk Kültür, Dil ve Tarih Yüksek Kurumu</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">İletişim linki : https://www.ayk.gov.tr</span></span></span></p>
<p class="western" align="justify"><span ><span style={{fontStyle:10}}><span lang="tr-TR">Merkez Adres : </span></span></span><span ><span style={{fontStyle:10}}>Ziyabey Cad. Nu:19 06520 Balgat – Çankaya / ANKARA</span></span></p>
<div class="clear"></div>



</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="secondary">
            <IntlMessages id="button.close" />
            </Button>
          </DialogActions>
        </Dialog>

 
  );
}


export default KVKKDialog;