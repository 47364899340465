import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Absis from './Absis';
import DKAUpdate from './DKAUpdate';
import SBTPUpdate from './SBTPUpdate';



export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  absis: Absis,
  dkaUpdate: DKAUpdate,
  sbtpUpdate: SBTPUpdate,

});
