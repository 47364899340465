import appLocaleData from 'react-intl/locale-data/tr';
import enMessages from '../locales/tr_TR.json';

const EnLang = {
  messages: {
    ...enMessages
  },
  locale: 'tr-TR',
  data: appLocaleData
};
export default EnLang;
