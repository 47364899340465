import {
    SET_UPDATE_FUNC,
} from "constants/ActionTypes"; 

const INIT_STATE = {
    func: null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_UPDATE_FUNC: {
            return {
                ...state,
                func: action.payload.func,
      
            }
        }
        default:
            return state;
    }
}
