import axios from 'axios';
//import config from '../../config';
import {envConfig as config} from '../../environment';
export const getInstitutionAllApi = async()=>{
   const url = `${config.apiUrl}/v1/institution/`;
   return await axios.get(url)
   .then(response => response.data)
}

export const getInstitutionByInstitutionIdApi = (institutionId)=>{
    const url = `${config.apiUrl}/v1/institution/${institutionId}`;
    return axios.get(url)
    .then(response => response.data);
 }


 export const postSaveInstitutionApi = (institution)=>{
    const url = `${config.apiUrl}/v1/institution/`;
    return axios.post(url,institution)
    .then(response => response.data);
 }

 export const putUpdateInstitutionApi = (institution)=>{
    const url = `${config.apiUrl}/v1/institution/`;
    return axios.put(url,institution)
    .then(response => response.data); 
 }
 
 export const deleteMultiInstitutionApi = (institutionIdList)=>{
   const url = `${config.apiUrl}/v1/institution/`;
   return axios.delete(url,{data:institutionIdList})
   .then(response => response.data);
}