import React from 'react';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

class SimpleSelect extends React.Component {
  state = {
    
    name: this.props.value,
   
  };

  handleChange = name => event => {
    this.setState({[name]: event.target.value});
    this.props.onChange(event.target.value);
  };

  render() {
      const {list} = this.props;
    return (
      <div className="row" autoComplete="off">
        <div className="col-lg-12 col-sm-12 col-12">
          <FormControl className="w-100 mb-2">
          <InputLabel htmlFor="titleName">{this.props.title}</InputLabel>

            <Select
              value={this.props.value}
              onChange={this.handleChange('name')}
              input={<Input id="titleName"/>}
            >
            
            <MenuItem value="">
                <em>Yok</em>
              </MenuItem>
              
              {list.map(item => {
                    return (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    );
               })}

            </Select>
          </FormControl>
        </div>
</div>
    );
  }
}

export default SimpleSelect;