import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getInstitutionAllApi } from '../../../api/institution';
import FormHelperText from '@material-ui/core/FormHelperText';
import IntlMessages from 'util/IntlMessages';

class InstitutionNativeSelect extends React.Component {
  state = {
 
  //  insName: '',
    insList : [],
  };

  handleChange = name => event => {
 //   this.setState({insName: event.target.value});
    this.props.onChange(event.target.value); 
  };

  
  componentDidMount =  async() =>{
    const insList = await getInstitutionAllApi();
    this.setState({insList: this.props.isAdmin ? insList :  insList.filter(x=>!["YK","AKM","ATAM","TDK","TTK"].filter(a=>a!==this.props.inst).some(y=>y===x.name))});
  }
   


  render() {
    const {insList} = this.state;
    const {institutionsErrorText,inst} = this.props;

    
    return (
    
          <FormControl className="w-100 my-2">
            <InputLabel 
            shrink id="insNameID" 
            htmlFor="insName"> {<IntlMessages id="user.institutions"/>}</InputLabel>
            <Select
              native
              margin="dense"
              error={institutionsErrorText==="" ? false : true}
             defaultValue={inst}
              value={inst}
              onChange={this.handleChange('insName')}
              input={<Input id="insName"/>}
              labelId="insNameID"
            >
              {/* <option value=""/> */}
              <option aria-label="None" value="" />
              
              {insList.map(item => {
                    return (
                        <option key={item.id} value={item.name}>{item.name}</option>
                    );
               })}
            </Select>
            <FormHelperText style={{color:"#f44336"}}> {institutionsErrorText}</FormHelperText>

          </FormControl>
        
    );
  }
}

export default InstitutionNativeSelect;
