
import {
    SET_ABSIS_USER 
} from 'constants/ActionTypes';

export const setAbsisUser = (user) => {
        return {
        type: SET_ABSIS_USER,
        payload: {user}
        };
};