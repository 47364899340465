import {
    SET_SBTP_UPDATE_STATE,
} from "constants/ActionTypes"; 

const INIT_STATE = {
    state_obj: null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SBTP_UPDATE_STATE: {
            return {
                ...state,
                state_obj: action.payload.state_obj,
      
            }
        }
        default:
            return state;
    }
}
