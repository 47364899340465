import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
//import SimpleSelect from '../../../components/routes/selects/simple/SimpleSelect';
import IntlMessages from 'util/IntlMessages';

//import SimpleSelect from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import {
  hideMessage,
  showAuthLoader,
  userActivation,

} from 'actions/Auth';

class ActivationUser extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      emailErrorText:'', 
    }

     
    
  }

  

  validate = ()=>{
    let isError = false;
    const errors = {};
   
    if(this.state.email.length < 1){
      isError = true;
      errors.emailErrorText = "Mail Adresi alanı zorunludur."
    }
    if(isError){
      this.setState({
        ...this.state,
        ...errors
      })
    }

    return isError;
  }

  urlParams = (url,name)=>{
    // eslint-disable-next-line
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }


  render() {

    const {
      email,
    } = this.state;

    const paramsString = this.props.location.search;
   // const params = new URLSearchParams(paramsString); 
    //const activationCode = params.get('ac');
  
    const activationCode = this.urlParams(paramsString,'ac');

   
//console.log("gelenler : ",this.props.location.search)
    

    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Tümak - I">
            {/* <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>        */}
            {
                this.props.locale.locale ==="en" ? 
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz_en.png")} width="250px" alt="IIS" title="IIS"/> 
                :
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>
            }
                 </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1><IntlMessages id="userActivation.title"/></h1>
            </div>

            <div className="mb-4">
             
            </div>

            <div className="app-login-form">
              <form method="post" action="/">
           
         

                <TextField
                  type="email"
                  onChange={(event) => this.setState({email: event.target.value, emailErrorText:""})}
                  label={<IntlMessages id="user.email"/>}
                  fullWidth
                  error= {this.state.emailErrorText === "" ? false : true }   
                  helperText={this.state.emailErrorText}
                  margin="normal"
                  className="mt-0 mb-2"
                />
                
                
                

              
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" onClick={() => {

                    const err = this.validate();
                    if(!err)
                    {
                      this.props.showAuthLoader();
                      this.props.userActivation({email,activationCode,lang:this.props.locale.locale});
                    }
                 
                  }} color="primary"> <IntlMessages id="userActivation.button"/>
                </Button>
                
                </div>
               

              </form>
            </div>
          </div>

        </div>

        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    )
  }
}

const mapStateToProps = ({auth,settings}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser,locale}
};

export default connect(mapStateToProps, {
  userActivation,
  hideMessage,
  showAuthLoader,
})(ActivationUser);
