import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  showAuthMessage, 
  userSignIn,
  userSignOut,
  userSignOutSuccess
} from 'actions/Auth';
import { envConfig } from '../environment';
import SweetAlert from 'react-bootstrap-sweetalert'
//import {Alert} from 'reactstrap';
//import ReCAPTCHA from "react-google-recaptcha";
import config from '../config';
import {Alert} from 'reactstrap';
import {switchLanguage} from 'actions/Setting';
// import ReCAPTCHA from "react-google-recaptcha";
import languageData from './../components/LanguageSwitcher/data';
//import BackGround from '../assets/images/bg/bg1.jpg'
import Canvas from './Canvas';
import ReCAPTCHA from "react-google-recaptcha";

class SignIn extends React.Component {
  


  constructor() {
    super();
    this.state = {
      email: process.env.REACT_APP_ENV ==="development" ? 'nakdag@ayk.gov.tr' : '',
      password: process.env.REACT_APP_ENV ==="development" ? '123456' : '',
      emailErrorText:'',
      passwordErrorText:'',
      error:false,
      errorMessage : '',
      captcha:false,
      recaptchaLoaded: false
    }
    this.recaptchaRef = React.createRef();

  }

  validate = ()=>{
    let isError = false;
    const errors = {};
   
    if(this.state.email.length < 1){
      isError = true;
      errors.emailErrorText = <IntlMessages id="signin.errormessage.emailAndIdentiy"/>
    }
    if(this.state.password.length < 6){
      isError = true;
      errors.passwordErrorText = <IntlMessages id="signin.errormessage.password"/>
    }

    if(isError){
      this.setState({
        ...this.state,
        ...errors
      })
    }

    return isError;
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }
  userSignInEdevlet (){


    const url = `${envConfig.eDevlet.directUrl}?response_type=code&client_id=${envConfig.eDevlet.clientID}&state=${Math.random()}&scope=Ad-Soyad;Iletisim-Bilgileri;Temel-Bilgileri;Adres-Bilgileri&redirect_uri=${envConfig.eDevlet.redirectUrl}`;
   // //console.log("Url :",url);

    window.location.replace(url);
  
  }

  urlParams = (url,name)=>{
    // eslint-disable-next-line
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }

  componentDidMount = ()=>{
    const paramsString = this.props.location.search;
    // const params = new URLSearchParams(paramsString); 
    // const status = params.get('status');
    // const message = params.get('message');

    const status = this.urlParams(paramsString,'status');
    const message = this.urlParams(paramsString,'message');
    ////console.log("params : ",status,message);
    if(status === "false")
    {
      this.props.userSignOutSuccess();
      localStorage.removeItem('userToken');
      localStorage.removeItem("user");
      // //console.log("burada",status)
    
        this.setState({error:true,errorMessage:message});
    }
    // else
    // {
    //   this.setState({error:false,errorMessage:""});

    // }
    
  }

  loginFunc = ()=>{
   
   console.log("loginFunc çalıştı");
    this.props.showAuthLoader();

    const res = this.props.userSignIn({email:this.state.email, password:this.state.password,isEdevlet:false,lang:this.props.locale.locale});
 
    console.log('res', res)
  }
  onRecaptchaSuccess =  (value) => {
    console.log("Captcha value:", value);
    this.recaptchaRef.current.reset();
    this.loginFunc();
  }
  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!", recaptchaLoaded: true });
    console.log("scriptLoad - reCaptcha Ref-", this.recaptchaRef);
  };

  render() {
    const {
      email,
      password
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
   // //console.log("İs Internet Explorer :",isIE);

    return (
      <div className="app-main" style={{
      //  backgroundImage:`url(${BackGround})`,
     //   backgroundSize:'cover'
     //background: 'black',
     background: 'linear-gradient(to bottom, #000000 0%, #5788fe 100%)',
      }}>
  

  <Canvas style={{position:'absolute'}} gui={false} />
  <div className="landscape"></div>
        <div className="filter"></div>
        

        
   <div 
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">



{
                      isIE ? 
                      <Alert className="shadow-lg" color="danger">
                     <IntlMessages id="signin.ieError"/>
                      </Alert> 
                      : 

        <div className="app-login-main-content" >


          <div className="app-logo-content d-flex align-items-center justify-content-center">

           
           

            <Link className="row" to="/" title="Bütünleşik Bilgi Sistemi">
              {
                this.props.locale.locale ==="tr" ? 
                
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>
                :
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz_en.png").default} width="250px" alt="IIS" title="IIS"/> 

            }
                             <img src={require("assets/images/100.png").default} style={{marginInline:"auto"}} width="200px" alt="IIS" /> 

            </Link>

             
          </div>



        


          <div className="app-login-content">


 
     
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.signIn"/></h1>
            </div>

            <div className="app-login-form">
              <form>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                onChange={this.onRecaptchaSuccess}
                sitekey={config.googleCaptchaSiteKey}
                asyncScriptOnLoad={this.asyncScriptOnLoad}
              /> 

                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email"/>}
                    fullWidth
                    onChange={(event) => this.setState({email: event.target.value , emailErrorText:""})}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    error= {this.state.emailErrorText === "" ? false : true }   
                    helperText={this.state.emailErrorText}
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password"/>}
                    fullWidth
                    onChange={(event) => this.setState({password: event.target.value , passwordErrorText:""})}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                    error= {this.state.passwordErrorText === "" ? false : true }  
                    helperText={this.state.passwordErrorText} 
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                   
                        const err = this.validate();
                        if(!err)
                        {
                        this.recaptchaRef.current.execute();                      
                        }

                      }
                    }}
                  />
                  <div className="m-3 align-items-center">

                  {config.maintanence &&
             <Alert className="shadow-lg"  color="danger">
             Bakım çalışması nedeniyle sistemimiz kısa bir süre için hizmet dışıdır. Anlayışınız için teşekkür ederiz.
              </Alert> 
          
          } 
                            
 
                    </div>
                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button  disabled={!this.state.recaptchaLoaded || config.maintanence} onClick={() => {


                          const err = this.validate();
                          if(!err)
                          { 
                            console.log("capcha çalıştı");
                            this.recaptchaRef.current.execute();
                          }


                    }} variant="contained" color="primary">
                      <IntlMessages id="signIn.signinButton"/>
                    </Button>
                    <Button disabled={config.maintanence} className="ml-4" onClick={() => {
                            this.userSignInEdevlet();

                          }} variant="contained" color="secondary">
                           <IntlMessages id="signIn.signInEdevlet"/>
                          </Button>
                         
                  
                    
                  </div>
                  <div className="mb-3 d-flex align-items-right justify-content-between">
                          <Link to="/signup">
                                                <IntlMessages id="signIn.signUp"/>
                                              </Link>
                                              
                                              <Link to="/forgotMyPassword">
                                                <IntlMessages id="signIn.forgotMyPassword"/>
                                              </Link>
                          </div>

                  

 <div className="app-social-block my-1 my-sm-3">
                    {
                      isIE ? 
                      <Alert className="shadow-lg" color="danger">
                     <IntlMessages id="signin.ieError"/>
                      </Alert> 
                      : null
                    }
                 
                  </div>

                  <div className="app-social-block my-1 my-sm-3">
                  {/* <LanguageSwitcher switchLanguage={this.props.switchLanguage}   handleRequestClose={ ()=> {}}/> */}
                  {languageData.map((language, index) => 
                          <div key={index} className="d-flex align-items-center pointer" onClick={() => {
                            this.props.switchLanguage(language);
                            localStorage.setItem("language",JSON.stringify(language));
                          }}>
                          <i className={`flag flag-24 flag-${language.icon}`}   style={{ backgroundImage: `url(${require("assets/images/flag.png").default})` }}  />
                          <h4 className="mb-0 ml-2">{language.name}</h4> 
                        </div> 
                    )}
                  
                   </div>

                  <div className="app-social-block my-1 my-sm-3">
                  <ul className="social-link mt-3 ml-auto mr-auto text-center">
                      <li>
                      <a href="https://www.ayk.gov.tr">
                      <img src={require("assets/images/ayk_logo.png")} width="30px" alt="Atatük Kültür, Dil ve Tarih Yüksek Kurumu" title="Atatük Kültür, Dil ve Tarih Yüksek Kurumu"/>
                      </a>
                      </li>  
                      <li>  
                      <a href="https://www.atam.gov.tr">
                      <img src={require("assets/images/atam.png").default} width="30px" alt="Atatürk Araştırma Merkezi" title="Atatürk Araştırma Merkezi"/>
                      </a>
                      </li> 
                       <li>
                       <a href="https://www.akmb.gov.tr">
                      <img src={require("assets/images/akm.png").default} width="30px" alt="Yüksek Kurum" title="Atatürk Kültür Merkezi Başkanlığı"/>
                      </a></li>
                    
                      <li>
                      <a href="https://www.tdk.gov.tr">
                      <img src={require("assets/images/tdk.png").default} width="30px" alt="Yüksek Kurum" title="Türk Dil Kurumu"/>
                      </a></li>
                      <li>
                      <a href="https://www.ttk.gov.tr">
                      <img src={require("assets/images/ttk.png").default} width="30px" alt="Yüksek Kurum" title="Türk Tarih Kurumu"/>
                      </a></li>
                      </ul>
                  </div>
               

                </fieldset>
              </form>
            </div>
          </div>

        </div>
  }
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
          
         
        <SweetAlert show={this.state.error}
                  warning
                  confirmBtnText={<IntlMessages id="button.ok"/>}
                  confirmBtnBsStyle="danger"
                  title={this.state.errorMessage}
                  onConfirm={()=>{
                    
                    this.setState({error:false,errorMessage:''})
                    window.location.replace("/");
                  }}
              >
        </SweetAlert>
        {/* <div className="app-login-main-content">
                  Not: Uygulamanın sorunsuz çalışması için Güncel tarayıcıları (Google Chrome,Mozilla Firefox, Edge, Safari) kullanınız.
                  </div> */}


      </div>


      </div>
   
    );
  }
}

const mapStateToProps = ({auth,settings}) => {
  const {loader, alertMessage, showMessage, authUser,userName} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser,userName,locale}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,showAuthMessage,
  showAuthLoader,userSignOut,userSignOutSuccess,switchLanguage
})(SignIn);
