import axios from 'axios';
//import config from '../../config';
import {envConfig as config} from '../../environment';
//import {history} from '../../store';
 

export const getAppFullAllApi = async()=>{
   const url = `${config.apiUrl}/v1/app/`;
   return await axios.get(url)
   .then(response => response.data).catch(error => { 
       //console.log("error ",error)
       //console.log("error ",error.response)
       if(error.response.status === 403)
         {
           localStorage.removeItem("userToken");
           window.location.href =             config.mainPageUrl + `?status=false&message=Oturumunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
         }
         else if(error.response.status === 401)
         {
           localStorage.removeItem("userToken");
           window.location.href =
             config.mainPageUrl + `?status=false&message=Oturumunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=401`;
         }

       return { status:false , error:error.response}  
      
      });
}

export const getAppByAppIdApi = (appId)=>{
    const url = `${config.apiUrl}/v1/app/${appId}`;
    return axios.get(url)
    .then(response => response.data).catch(error => { 
      //console.log("error ",error)
      //console.log("error ",error.response)
      if(error.response.status === 403)
        {
          localStorage.removeItem("userToken");
          window.location.href =
            config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
        }
        else if(error.response.status === 401) 
        {
          localStorage.removeItem("userToken");
          window.location.href =
            config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=401`;
        }

      return { status:false , error:error.response}  
     
     });
 }

 export const getAppFullByUserIdApi = (userId)=>{
   const url = `${config.apiUrl}/v1/app/full/${userId}`;
   return axios.get(url)
   .then(response => response.data).catch(error => { 
      //console.log("error ",error)
      //console.log("error ",error.response)
      if(error.response.status === 403)
        {
          localStorage.removeItem("userToken");
          window.location.href =
            config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=403`;
        }
        else if(error.response.status === 401)
        {
          localStorage.removeItem("userToken");
          window.location.href =
            config.mainPageUrl + `?status=false&message=Oturmunuzun süresi dolmuştur. Yeniden giriş yapınız.&code=401`;
        }

      return { status:false , error:error.response}  
     
     }); 
}

