import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import ColorOption from "../containers/Customizer/ColorOption";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {this.props.authUser.iban === "" ||
        this.props.authUser.bankName === "" ||
        this.props.authUser.iban === null ||
        this.props.authUser.bankName === null ? (
          <Tour />
        ) : null}

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  path={`${match.url}/mainPage`}
                  component={asyncComponent(() =>
                    import("./routes/SamplePage")
                  )}
                />
                <Route
                  path={`${match.url}/`}
                  exact
                  component={asyncComponent(() =>
                    import("./routes/SamplePage")
                  )}
                />
                <Route
                  path={`${match.url}/users`}
                  component={asyncComponent(() => import("./routes/Users"))}
                />
                <Route
                  path={`${match.url}/institutions`}
                  component={asyncComponent(() =>
                    import("./routes/Institutions/InstitutionsPage")
                  )}
                />
                <Route
                  path={`${match.url}/profile`}
                  component={asyncComponent(() =>
                    import("./routes/Users/Profile")
                  )}
                />
                <Route
                  path={`${match.url}/notification`}
                  component={asyncComponent(() =>
                    import("./routes/notification")
                  )}
                />
                <Route
                  path={`${match.url}/absis`}
                  exact
                  component={asyncComponent(() => import("./routes/absis"))}
                />
                <Route
                  path={`${match.url}/absis/userDetail/:userId`}
                  component={asyncComponent(() =>
                    import("./routes/absis/AbsisUserDetail")
                  )}
                />
                <Route
                  path={`${match.url}/absis/userSearch`}
                  component={asyncComponent(() =>
                    import("./routes/absis/AbsisUserSearch")
                  )}
                />
                 <Route
                  path={`${match.url}/absis/scienceBoard`}
                  component={asyncComponent(() =>
                    import("./routes/absis/AbsisScienceBoard")
                  )}
                />
                <Route
                  path={`${match.url}/error403`}
                  component={asyncComponent(() =>
                    import("components/Error403")
                  )}
                />
                <Route
                  path={`${match.url}/error401`}
                  component={asyncComponent(() =>
                    import("components/Error401")
                  )}
                />
               <Route
                  path={`${match.url}/fileServer`}
                  component={asyncComponent(() =>
                    import("./routes/fileServer/FileServerPage")
                  )}
                />
                 <Route
                  path={`${match.url}/archiveSearch`}
                  component={asyncComponent(() =>
                    import("./routes/fileServer/ArchiveSearch")
                  )} 
                />
                 <Route
                  path={`${match.url}/opinionsPage`}
                  component={asyncComponent(() =>
                    import("./routes/opinions/OpinionsPage")
                  )}
                />
  <Route
                  path={`${match.url}/documentArchive`}
                  component={asyncComponent(() =>
                    import("./routes/documentArchive/DocumentArchivePage")
                  )}
                />
                  <Route
                  path={`${match.url}/imageArchive`}
                  component={asyncComponent(() =>
                    import("./routes/imageArchive/ImageArchivePage")
                  )}
                />
                  <Route
                  path={`${match.url}/videoArchive`}
                  component={asyncComponent(() =>
                    import("./routes/videoArchive/VideoArchivePage")
                  )}
                />

  <Route
                  path={`${match.url}/ourOwnInstitutionsUserPage`}
                  component={asyncComponent(() =>
                    import("./routes/Users/OurOwnInstitutionsUserPage")
                  )}
                />
  <Route
                  path={`${match.url}/roleManagePage`}
                  component={asyncComponent(() =>
                    import("./routes/Users/RoleManagePage")
                  )}
                />
                <Route
                  path={`${match.url}/previewBookPDF`}
                  component={asyncComponent(() =>
                    import("./routes/fileServer/PreviewBookPDF")
                  )}
                />
                 <Route
                  path={`${match.url}/gifts`}
                  component={asyncComponent(() =>
                    import("./routes/gifts/GiftsPage")
                  )} 
                />
                <Route
                  component={asyncComponent(() =>
                    import("components/Error404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { authUser } = auth;
  return { drawerType, navigationStyle, horizontalNavPosition, authUser };
};
export default withRouter(connect(mapStateToProps)(App));
