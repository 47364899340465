import React from 'react';
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import SweetAlert from 'react-bootstrap-sweetalert'
import {    showAuthLoader,userSignIn,setInitUrl,userSignInSuccess } from 'actions/Auth';
import { postLoginEdevlet2Api } from '../api/auth';
import {history} from '../store';
import SimpleCrypto from "simple-crypto-js";
import config from '../config';
import { envConfig } from '../environment';
import { postLogSave } from '../api/utils';


class SignInEdevlet extends React.Component {
  constructor() {
    super();
    this.state = {
      warningMessage: '',
      warningState: false,
      user : {},
      userUpdateDialogState :false,
     
    } 

  }
  
  urlParams = (url,name)=>{
    // eslint-disable-next-line
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }
 componentDidMount = async()=>{

  const paramsString = this.props.location.search;
  const reqURL = this.props.location.pathname;
//  const params = new URLSearchParams(paramsString); 
  const authorization_code = this.urlParams(paramsString,'code');


  if(reqURL==="/login_edevlet_destek/" && authorization_code===null)
  {
    
    const url = `${envConfig.eDevlet.destek.directUrl}?response_type=code&client_id=${envConfig.eDevlet.destek.clientID}&state=${Math.random()}&scope=Ad-Soyad;Iletisim-Bilgileri;Temel-Bilgileri;Adres-Bilgileri&redirect_uri=${envConfig.eDevlet.destek.redirectUrl}`;
    ////console.log("Url :",url);

    window.location.replace(url);
  }
  else if(reqURL==="/login_edevlet_proje/" && authorization_code===null)
  {
    
    const url = `${envConfig.eDevlet.proje.directUrl}?response_type=code&client_id=${envConfig.eDevlet.proje.clientID}&state=${Math.random()}&scope=Ad-Soyad;Iletisim-Bilgileri;Temel-Bilgileri;Adres-Bilgileri&redirect_uri=${envConfig.eDevlet.proje.redirectUrl}`;
    ////console.log("Url :",url);

    window.location.replace(url);
  }else
  {
      // const authorization_code  = params.get('code');
     // //console.log("Edevletden gelen authorization_code : ",authorization_code );
     // //console.log("reqURL",reqURL)

      const result = await postLoginEdevlet2Api({authorization_code,app_name:"bbs"}).catch(err=>{
        return {
          status:false,
          message :"Hata Oluştu: "+err
        }
      });
    

      if(result.status) 
      { 
        ///giriş başarılı
        ////console.log("giriş başarılı") 

        const {user} = result;
            //this.props.userSignIn({email:user.identityNo,password:user.identityNo,isEdevlet:true});
            //this.props.setInitUrl('/bbs/mainPage');
            const simpleCrypto = new SimpleCrypto(config.sk);

            localStorage.setItem("user", simpleCrypto.encryptObject(user));
            localStorage.setItem("userToken", result.token);


            this.setState({user})

            this.props.userSignInSuccess(user);

            

            if(reqURL==="/login_edevlet_destek/")
            {
                  const data = {
                    userId : user.id,
                    pwd : user.pwd,
                    first: 1,
                    roleId : 40,
                    token : localStorage.getItem("userToken")
                  };
                  postLogSave({
                    userId :  data.userId,
                    info : "Destek E-devlet Login",
                    appId : 9 ,
                    opType:1,
                    processId : 28
                  })

                  
                  const appUrl = "https://destek.ayk.gov.tr/destek";
              //    let url = `${appUrl}?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr`;
                  let url = `${appUrl}?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;

                //  //console.log(url);
                  window.location.replace(url); 
            
            }
            else if(reqURL==="/login_edevlet_proje/"){
                  const data = {
                    userId : user.id,
                    pwd : user.pwd,
                    first: 1,
                    roleId : 20,
                    token : localStorage.getItem("userToken")
                  };

                  postLogSave({
                    userId :  data.userId,
                    info : "Proje E-devlet Login",
                    appId : 10 ,
                    opType:1,
                    processId : 28
                  })


                  const appUrl = "https://proje.ayk.gov.tr/proje";
               //   let url = `${appUrl}?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr`;
                  let url = `${appUrl}?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;

               //   //console.log(url);
                  window.location.replace(url); 
            } 
            else
            {

              // if(user.inst ===  '' || user.email === '' || user.tel === ''|| user.gender === '' || user.city === '' || user.country === '' || user.address === '' || user.department === '')
              // {
              //   //burada profil günceleme prencersesini aç.
              //   this.setState({user:user , userUpdateDialogState:true});

              // }
              // else
              // {
                history.push('/bbs/mainPage');

             // }


            }
          
      }
      else
      {
        this.setState({
          warningState:true,
          warningMessage :result.message
        })
      }
  }

    
       
 }

  gotoMainPage = ()=>{
  history.push('/bbs/mainPage');

 }


  render() {
  

    const { loader} = this.props;


    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">



          <div className="app-logo-content d-flex align-items-center justify-content-center">

         
           
           

            <Link className="row" to="/" title="Bütünleşik Bilgi Sistemi">
              <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="300px" alt="BBS" title="BBS"/>
            </Link>

            
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>E-Devlet Yöntemi ile giriş yapılıyor. Lütfen bekleyiniz.</h1>
            </div>

         
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }

        <SweetAlert show={this.state.warningState}
            warning
            confirmBtnText={"Tamam"}
            confirmBtnBsStyle="danger"
            title={this.state.warningMessage}
            onConfirm={()=> {
              
              this.setState({warningState:false, warningMessage:""})
              localStorage.removeItem("userToken");
         //     this.props.hideAuthLoader();
              this.props.history.push('/signin');

            }
            }
         
            >
        </SweetAlert>

      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser,userName} = auth;
  return {loader, alertMessage, showMessage, authUser,userName}
};

export default connect(mapStateToProps, {
  userSignIn,
  setInitUrl,
  showAuthLoader,
  userSignInSuccess
})(SignInEdevlet);
