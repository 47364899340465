export default {
      defaultProfileImageUrl: "/uploads/images/user.jpg",

      maintanence:false, //bakım çalışması

    copright:'Her hakkı saklıdır. Atatürk Kültür, Dil ve Tarih Yüksek Kurumu',
    coprightYear:'2019',
  
    sk : "BBSuserKey123",
    googleCaptchaSiteKey : "6LdJmdQUAAAAABmHLRK9aMZ1ICt2gifnwK0YLosM",
    googleCaptchaPrivateKey : "6LdJmdQUAAAAADiNFF1wHMt9_kHdGqqnB97XVE7x",
   // apiUrl:"http://192.168.55.66:3300", 
   // apiUrl:"http://node2.ayk.gov.tr/api/",

    devConfig: {
      apiUrl:"http://localhost:3301",
     // apiUrl:"http://192.168.55.66:3301", 
      mainPageUrl : "http://localhost:3000/",
      bilsisUrl : "http://localhost:3001",  
      yaysisUrl : "http://localhost:3001", 
      kurulsisUrl : "http://testkurul.ayk.gov.tr", 
      eDevlet :
            {
                  directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet?first=1",
                  clientID : "0568958f-143c-4021-b3c5-13aefebaac48",
                  key : "FUkQcJ3D1yOveOf5CNQbsLsQh3zcxJm9",

                  destek :   {
                        directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                        accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                        resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                        redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet_destek?first=1",
                        clientID : "3e1d1c51-142f-4bb1-be05-ca62c43716cf",
                        key : "09MjEJNg2Ty4jUCeRfBQkLlNxLLAm7OA",
                  }, 
                  proje :   {
                        directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                        accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                        resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                        redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet_proje?first=1",
                        clientID : "3c39481f-3f2b-4c97-8e8b-b63a851f3749",
                        key : "lYbyJvNbfbaNO195Kh1cmRamx0eXEnel",
                  }
            }

    },
    testConfig:{
      apiUrl:"https://testgiris.ayk.gov.tr/api/",
      mainPageUrl : "/",
      bilsisUrl : "http://testbilsis.ayk.gov.tr",
      yaysisUrl : "http://testyaysis.ayk.gov.tr",
      kurulsisUrl : "http://testkurul.ayk.gov.tr", 

      eDevlet :
      {
            directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
            accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
            resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
            redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet?first=1",
            clientID : "0568958f-143c-4021-b3c5-13aefebaac48",
            key : "FUkQcJ3D1yOveOf5CNQbsLsQh3zcxJm9",
            
            destek :   {
                  directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet_destek?first=1",
                  clientID : "3e1d1c51-142f-4bb1-be05-ca62c43716cf",
                  key : "09MjEJNg2Ty4jUCeRfBQkLlNxLLAm7OA",
            },
            proje :   {
                  directUrl : "https://test.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://testgiris.ayk.gov.tr/login_edevlet_proje?first=1",
                  clientID : "3c39481f-3f2b-4c97-8e8b-b63a851f3749",
                  key : "lYbyJvNbfbaNO195Kh1cmRamx0eXEnel",
            }
      }
    },
    prodConfig:{
      apiUrl:"https://giris.ayk.gov.tr/api/",
      mainPageUrl : "/",
      bilsisUrl : "http://bilsis.ayk.gov.tr",
      yaysisUrl : "http://yaysis2.ayk.gov.tr",
      kurulsisUrl : "https://kurulsis.ayk.gov.tr", 

      eDevlet :
      {
            directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
            accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
            resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
            redirectUrl : "https://giris.ayk.gov.tr/login_edevlet?first=1",
            clientID : "0568958f-143c-4021-b3c5-13aefebaac48",
            key : "FUkQcJ3D1yOveOf5CNQbsLsQh3zcxJm9",
            serviceId: 95903 ,

            
            destek :   {
                  directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://giris.ayk.gov.tr/login_edevlet_destek?first=1",
                  clientID : "3e1d1c51-142f-4bb1-be05-ca62c43716cf",
                  key : "09MjEJNg2Ty4jUCeRfBQkLlNxLLAm7OA",
                  serviceId: 95839

            },
            proje :   {
                  directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://giris.ayk.gov.tr/login_edevlet_proje?first=1",
                  clientID : "3c39481f-3f2b-4c97-8e8b-b63a851f3749",
                  key : "lYbyJvNbfbaNO195Kh1cmRamx0eXEnel",
                  serviceId: 95962

            },              
            yaysis :   {
                  directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://giris.ayk.gov.tr/login_edevlet_yaysis?first=1",
                  clientID : "7be76591-3ef5-4d29-8edf-820f10c2c324",
                  key : "A0gRmU2SjgDJklgDuC3TW8sWdKvuBewj",
                  serviceId: 95903

            },
            bilsis :   {
                  directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://giris.ayk.gov.tr/login_edevlet_bilsis?first=1",
                  clientID : "03a76cd0-a447-400c-ae9b-811a1b4f4c2b",
                  key : "eOq3aohw3oP77z91Xk8ALrBhu6vBwZvl",
                  serviceId: 117633
            },
            absis :   {
                  directUrl : "https://giris.turkiye.gov.tr/OAuth2AuthorizationServer/AuthorizationController",
                  accessTokenUrl : "https://gwesb.turkiye.gov.tr:8443/oauth/AccessTokenController",
                  resourceControllerUrl: "https://gwesb.turkiye.gov.tr:8443/oauth/ResourceController",
                  redirectUrl : "https://giris.ayk.gov.tr/login_edevlet_absis?first=1",
                  clientID : "7a5001a1-6afb-4411-be55-aa4ce17268e1",
                  key : "kTrptVQ4ru1CjlCEYW3OMXV7vOI5qt9O",
                  serviceId: 117634

            },
      }
    }, 

    absis : {
            orcid : {
                  apiUrl:"https://pub.orcid.org/v2.1",
            }
    },
    journals : [
      { id : 1 , name : 	"Türk Dili", inst : 	"TDK"},
      { id : 2 , name : 	"Türk Dünyası", inst : 	"TDK"},
      { id : 3 , name : 	"Belleten", inst : 	"TDK"},
      { id : 4 , name : 	"Belleten", inst : 	"TTK"},
      { id : 5 , name : 	"Belgeler", inst : 	"TTK"},
      { id : 6 , name : 	"Atatürk Araştırma Merkezi Dergisi", inst : 	"ATAM"},
      { id : 7 , name : 	"Erdem", inst : 	"AKM"},
      { id : 8 , name : 	"Bilge", inst : 	"AKM"},
      { id : 9 , name : 	"Arış", inst : 	"AKM"},
      { id : 10 , name : 	"Höyük", inst : 	"TTK"},
   
     ],
     
    titleList : [
        "Dr.",
        "Dr. Öğretim Üyesi",
        "Doç. Dr.",
        "Prof. Dr.",
        "Öğretim Görevlisi",
        "Araştırma Görevlisi",
        "Öğretmen",
      //  "Diğer",
    ],


    titleListLong: [
      "Araştırma Profesörü",
      "Doçent",
      "Doktor",
      "Doktor Öğretim Üyesi",
      "Yardımcı Doçent",
      "Ordinaryüs",
      "Profesör",	
      "Araştırma Görevlisi",
      "Çevirmen",
      "Okutman",
      "Öğretim Görevlisi",
      "Öğretim Planlamacısı",
      "Uzman",
      "Öğretmen"],

      degreeListLong: [ 
            "Önlisans",
            "Lisans",
            "Lisans-Anadal",
            "Lisans-Yandal",
            "Lisans Tamamlama",
            "Bilimsel Hazırlık",
            "Yüksek Lisans",
            "Yüksek Lisans - Tezli",
            "Yüksek Lisans - Tezsiz",
            "Bütünleşik Doktora",
            "Doktora"
           ],
      
    mounth:["Ocak","Şubat","Mart","Nisan","Mayıs","Haziran","Temmuz","Ağustos","Eylül","Ekim","Kasım","Aralık"],
    citys : ['','Adana', 'Adıyaman', 'Afyon', 'Ağrı', 'Amasya', 'Ankara', 'Antalya', 'Artvin',
    'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu', 'Burdur', 'Bursa', 'Çanakkale',
    'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır', 'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir',
    'Gaziantep', 'Giresun', 'Gümüşhane', 'Hakkari', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir', 
    'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya', 'Kütahya', 'Malatya', 
    'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş', 'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya',
    'Samsun', 'Siirt', 'Sinop', 'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
    'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale', 'Batman', 'Şırnak',
    'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük', 'Kilis', 'Osmaniye', 'Düzce'],
  
   
      educationLevels : [ "","Okur-Yazar Değil", "Okur-Yazar","İlkokul","Ortaokul","Lise","Lisans","YüksekLisans","Doktora"],

      fileAcceptExtension : [
         'video/x-flv'
        ,'video/mp4'
        ,'application/x-mpegURL'
        ,'video/MP2T'
        ,'video/3gpp'
        ,'video/quicktime'
        ,'video/x-msvideo'
        ,'video/x-ms-wmv'
        ,'audio/basic'
        ,'audio/mid'	
        ,'audio/mpeg	RFC 3003'
        ,'audio	audio/mp4'	
        ,'audio/x-mpegurl'
        ,'Vorbis	audio/ogg	RFC 5334'
        ,'audio/vnd.wav'
        ,'image/cgm'
        ,'image/fits'
        ,'image/g3fax'
        ,'image/gif'
        ,'image/ief'
        ,'image/jp2'
        ,'image/jpeg'
        ,'image/jpm'
        ,'image/jpx'
        ,'image/naplps'
        ,'image/png'
        ,'image/prs.btif'
        ,'image/prs.pti'
        ,'image/t38'
        ,'image/tiff'
        ,'image/tiff-fx'
        ,'image/vnd.adobe.photoshop'
        ,'image/vnd.cns.inf2'
        ,'image/vnd.djvu'
        ,'image/vnd.dwg'
        ,'image/vnd.dxf'
        ,'image/vnd.fastbidsheet'
        ,'image/vnd.fpx'
        ,'image/vnd.fst'
        ,'image/vnd.fujixerox.edmics-mmr'
        ,'image/vnd.fujixerox.edmics-rlc'
        ,'image/vnd.globalgraphics.pgb'
        ,'image/vnd.microsoft.icon'
        ,'image/vnd.mix'
        ,'image/vnd.ms-modi'
        ,'image/vnd.net-fpx'
        ,'image/vnd.sealed.png'
        ,'image/vnd.sealedmedia.softseal.gif'
        ,'image/vnd.sealedmedia.softseal.jpg'
        ,'image/vnd.svf'
        ,'image/vnd.wap.wbmp'
        ,'image/vnd.xiff'
        ,'application/msword'
        ,'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ,'application/vnd.ms-powerpoint'
        ,'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ,'application/pdf'
      ]
}