import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_USER_SUCCESS, 
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    UPDATE_USER,
    INIT_REDIRECT_URL
} from "constants/ActionTypes";
import config from "../config";
import SimpleCrypto from "simple-crypto-js";
//import 'react-app-polyfill/ie11';

const simpleCrypto = new SimpleCrypto(config.sk);

let user = null;
try {
   user = simpleCrypto.decrypt(localStorage.getItem("user"));
} catch (error) {
    user = null;
    localStorage.setItem("user",null)
}

//console.log("user::::",localStorage.getItem("user"))
const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    redirectUrl:null,
   // userName: localStorage.getItem('userName'),
   authUser: localStorage.getItem("user")===null ? null : user,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case INIT_REDIRECT_URL: {
            return {
                ...state,
                redirectUrl: action.payload
            }
        }

        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload,
                initURL: state.redirectUrl === null ? '/bbs/mainPage' : state.redirectUrl,
               // userName: action.payload.name + ' '+ action.payload.surname
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: '/signin',
              
                loader: false,
            //    userName:""
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_USER: {
               return {
                   ...state,
                   loader: false,
                   authUser: action.payload,
               //    userName: action.payload.name + ' '+ action.payload.surname,
               }
           }
        default:
            return state;
    }
}
