import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  userSignUp,
} from 'actions/Auth';
import InstitutionNativeSelect from '../app/routes/Institutions/InstitutionNativeSelect';
import SimpleSelect from '../app/routes/SimpleSelect/SimpleSelect';
import config from '../config';
import ReCAPTCHA from "react-google-recaptcha";
import { FormControl, FormLabel, MenuItem , Radio,FormControlLabel,RadioGroup, Checkbox} from '@material-ui/core';
import KVKKDialog from '../app/routes/dialogs/KVKKDialog';
const recaptchaRef = React.createRef();

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '', 
      surname:'',
      email: '',
      password: '',
      passwordReply:'',
      inst : "" ,
      title : '',
      identityNo:'',
      gender:"",
      nationality:"T.C.",
      tel:"",
      nationalityErrorText:"",
      genderErrorText : "",
      identity_noErrorText : '',
      nameErrorText: '', 
      surnameErrorText:'',
      emailErrorText:'',
      passwordErrorText:'',
      passwordReplyErrorText:'',
      institutionsErrorText:<IntlMessages id="signin.institutionMessage"/> ,
      kvkk:false,
      showKVKKDialog:false,
    };

    this.onChangeInstitutions = this.onChangeInstitutions.bind(this);
  }

  onChangeInstitutions = (value)=>{

    this.setState({
      inst : value,
      institutionsErrorText:""
    });
  }
  onChangeTitle = (value)=>{

    this.setState({
      title :value==="Diğer" ? "" : value
    });
  }
  onChangeGender = (value)=>{

    this.setState({
      gender :value
    });
  }
  validate = ()=>{
    let isError = false;
    const errors = {};

 
    if(this.state.name.length < 1){
      isError = true;
      errors.nameErrorText =<IntlMessages id="signin.errormessage.name"/>
    }

    if(this.state.surname.length < 1){
      isError = true;
      errors.surnameErrorText =<IntlMessages id="signin.errormessage.surname"/>
    }
    if(this.state.identityNo.length < 1){
      isError = true;
      errors.identity_noErrorText = this.state.nationality=== "T.C." ? <IntlMessages id="signin.errormessage.idetityNo"/> : <IntlMessages id="signin.errormessage.passportNo"/>
    }
    if(this.state.email.length < 1){
      isError = true;
      errors.emailErrorText =<IntlMessages id="signin.errormessage.email"/>
    }
    // if(this.state.gender.length < 1){
    //   isError = true;
    //   errors.genderErrorText =<IntlMessages id="signin.errormessage.gender"/>
    // }
    if(this.state.password.length < 6){
      isError = true;
      errors.passwordErrorText = <IntlMessages id="signin.errormessage.password"/>
    }
    if(this.state.passwordReply.length < 6){
      isError = true;
      errors.passwordReplyErrorText = <IntlMessages id="signin.errormessage.password"/>
    }
    if(this.state.passwordReply !== this.state.password){
      isError = true;
      errors.passwordErrorText = <IntlMessages id="signin.errormessage.passwordReply"/>
      errors.passwordReplyErrorText = <IntlMessages id="signin.errormessage.passwordReply"/>
    }
    if(this.state.institutionId === 0){
      isError = true;
      errors.institutionsErrorText = <IntlMessages id="signin.errormessage.institution"/>
     
    }
    if(this.state.nationality === ""){
      isError = true;
      errors.nationalityErrorText = <IntlMessages id="signin.errormessage.nationality"/>
     
    }
    if(!this.state.kvkk ){
      isError = true;
     // errors.kvkk = "KVKK metnini, onaylamadan devam edemezsiniz."
      NotificationManager.error( <IntlMessages id="signin.errormessage.kvkk"/>)
    }

    if(isError){
      this.setState({
        ...this.state,
        ...errors
      })
    }

    return isError;
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }
  onResolved= ()=>{
    this.props.showAuthLoader();
    const {email, password,name,surname,inst,identityNo,passwordReply,title,gender , nationality,tel} = this.state;
    this.props.userSignUp({email, password,name,surname,inst,identityNo,passwordReply,title,gender,lang:this.props.locale.locale,nationality,tel});
    ////console.log("capcha çalıştı 2")
  }

  
  signUpFunc = ()=>{
    const {
      name,
      surname,
      email,
      password,
      gender,
      passwordReply,
       inst,
       title,
      identityNo,
      nationality,
      tel,
      kvkk,

    } = this.state;

   // //console.log("loginFunc çalıştı");
    this.props.showAuthLoader();
    this.props.userSignUp({email, password,name,surname,inst,identityNo,passwordReply,title,gender,lang:this.props.locale.locale,nationality,tel,kvkk});
    
  }
  onRecaptchaSuccess = (value) => {
   // //console.log("Captcha value:", value);
    this.signUpFunc();
  }

  render() {
    const {
      name,
      surname,
      email,
      password,
      gender,
      passwordReply,
       //inst,
       //title,
      identityNo,
      tel,
      kvkk,
      showKVKKDialog

    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-signup-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content-signup d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="BBS">
            {
                this.props.locale.locale ==="en" ? 
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz_en.png")} width="100px" alt="IIS" title="IIS"/> 
                :
                <img src={require("assets/images/bbs_logo/logo_buyuk_beyaz.png").default} width="150px" alt="BBS" title="BBS"/>
            }
             
            </Link>
          </div>

          <div className="app-login-content-signup">
            <div className="app-login-header">
              <h1><IntlMessages id="appModule.createAccount"/></h1>
            </div>

           



            <div className="app-login-form">
              <form method="post" action="/">

                <div className="row">
                  <div className="col-md-6">
                  <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                onChange={this.onRecaptchaSuccess}
                sitekey={config.googleCaptchaSiteKey}
                
              />

                <TextField
                   error= {this.state.nameErrorText === "" ? false : true }   
                  required
                  type="text"
                  label={<IntlMessages id="user.name"/>}
                  onChange={(event) => this.setState({
                    name: event.target.value,
                    nameErrorText:""
                  })}
                  fullWidth
                  defaultValue={name}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.nameErrorText}
                />
          

<FormControl component="fieldset"  >
  <FormLabel component="legend"><IntlMessages id="user.nationality"/></FormLabel>
  <RadioGroup row aria-label="gender" name="gender1" value={this.state.nationality} onChange={(event) => this.setState({nationality: event.target.value, nationalityErrorText:""})}>
    <FormControlLabel value="T.C." control={<Radio />} label="T.C." />
    <FormControlLabel value="" control={<Radio />} label={<IntlMessages id="other"/>} />    
    {/* <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
    
    <TextField
    disabled={this.state.nationality=== "T.C."}
    style={{width:'40%'}}
                  type="text"
                  label={<IntlMessages id="user.nationality"/>}
                  onChange={(event) => this.setState({nationality: event.target.value, nationalityErrorText:""})}
                  error={this.state.nationalityErrorText === "" ? false : true }
                  helperText={this.state.nationalityErrorText}
                />
  </RadioGroup>
</FormControl>
<SimpleSelect 
                value={this.state.title}
                list={config.titleList} 
                onChange={this.onChangeTitle}
                title={<IntlMessages id="user.title"/>}
                />

<TextField
                required
                  type="email"
                  error= {this.state.emailErrorText === "" ? false : true }   
                  onChange={(event) => {
                    this.setState({email: event.target.value, emailErrorText:""})}
                  }
                  label={<IntlMessages id="user.email"/>}
                  fullWidth
                  defaultValue={email}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.emailErrorText}
                />


<TextField
                  required
                  error= {this.state.passwordErrorText === "" ? false : true }   
                  type="password"
                  onChange={(event) => {
                    this.setState({password: event.target.value, 
                                  passwordErrorText:""});
                                  if(this.state.passwordReply === event.target.value)
                                    {
                                      this.setState({passwordReplyErrorText:""});
                                    }
                                
                                }
                }
                  label={<IntlMessages id="user.password"/>}
                  fullWidth
                  defaultValue={password}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.passwordErrorText}
                />







                  </div>
                  <div className="col-md-6">
                  <TextField
                  error= {this.state.surnameErrorText === "" ? false : true }   
                 required
                  type="text"
                  label={<IntlMessages id="user.surname"/>}
                  onChange={(event) => this.setState({surname: event.target.value,
                    surnameErrorText:""})}
                  fullWidth
                  defaultValue={surname}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.surnameErrorText}
                />
                 <TextField
                  error= {this.state.identity_noErrorText === "" ? false : true }   
                 required
                  type="text"
                  label={this.state.nationality=== "T.C." ? <IntlMessages id="user.identityNo"/> : <IntlMessages id="user.passportNo"/>}
                  onChange={(event) => this.setState({identityNo: event.target.value,
                    identity_noErrorText:""})}
                  fullWidth
                  defaultValue={identityNo}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.identity_noErrorText}
                />
             
  
                  
                    <TextField
            id="gender"
            select
            label={<IntlMessages id="user.gender"/>}
            value={gender}
            SelectProps={{}}
            margin="normal"
            fullWidth
            onChange={(event) => this.setState({gender: event.target.value})}
            required   
          >
           <MenuItem key={1} value={"Erkek"}>
           <IntlMessages id="user.gender.man"/>
             </MenuItem>
             <MenuItem key={2} value={"Kadın"}>
             <IntlMessages id="user.gender.woman"/>
             </MenuItem>
          </TextField>
          

             
                  <TextField
                required
                  type="tel"
                  onChange={(event) => {
                    this.setState({tel: event.target.value})}
                  }
                  label={<IntlMessages id="contact.mobilePhone"/>}
                  fullWidth
                  defaultValue={tel}
                  margin="normal"
                  className="mt-0 mb-2"
                />

           
                  <TextField
                  required
                  error= {this.state.passwordReplyErrorText === "" ? false : true }   
                  type="password"
                  onChange={(event) => {
                    this.setState({passwordReply: event.target.value,
                    passwordReplyErrorText:""});
                    if(this.state.password === event.target.value)
                    {
                      this.setState({passwordErrorText:""});
                    }
                
                  
                  }
                  }
                  label={<IntlMessages id="user.passwordReply"/>}
                  fullWidth
                  defaultValue={passwordReply}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={this.state.passwordReplyErrorText}
                />
                {this.state.nationality === "T.C." ?
                <InstitutionNativeSelect  
                 onChange={this.onChangeInstitutions}
                 institutionsErrorText = {this.state.institutionsErrorText}
              /> : 
                 <TextField
                required
                  type="text"
                  error= {this.state.institutionsErrorText === "" ? false : true }   
                  onChange={(event) => {
                    this.setState({inst: event.target.value, institutionsErrorText:""})}
                  }
                  label={<IntlMessages id="user.institutions"/>}
                  fullWidth
                  defaultValue={this.state.inst}
                  margin="normal"
                  className="mt-0 mb-2"
                  helperText={<IntlMessages id="signin.institutionMessage2"/>}
                />

                }

                    </div> 
                </div>
         
         <div className="row my-3">
           <div className="col-md-12">
           <FormControlLabel control={<Checkbox checked={kvkk} onChange={(e,checked)=>this.setState({kvkk:checked}) }/>} label={ <span><Link component="button" to="#"
  variant="body2"
           onClick={(e)=>{
           //  e.preventDefault();
             this.setState({showKVKKDialog:true})
             }}> <IntlMessages id="signin.kvkk"/></Link> <IntlMessages id="signin.kvkk2"/></span>}/>

           </div>

         </div>



                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" onClick={(e) => {
                  
                  //  e.preventDefault();
                    const err = this.validate();
                    if(!err)
                    {
                      
                    //  //console.log("capcha çalıştı");
                      recaptchaRef.current.execute();

                    }
                    
                  }} color="primary">
                    <IntlMessages
                      id="appModule.regsiter"/>
                  </Button>
                  <Link to="/signin">
                    <IntlMessages id="signUp.alreadyMember"/>
                  </Link>
                </div>
                <div className="app-social-block my-1 my-sm-3">
             
                </div> 

              </form>
            </div>
          </div>

        </div>
        {
          showKVKKDialog &&
          <KVKKDialog open={showKVKKDialog} onClose={()=>this.setState({showKVKKDialog:false})} />
        }

        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
    )
  }
}

const mapStateToProps = ({auth,settings}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser,locale}
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
})(SignUp);
