import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { getAppFullAllApi, getAppFullByUserIdApi } from "../../api/app";
//import {history} from '../../store';
import { showAuthMessage, userSignOut } from "actions/Auth";
import { getOpinionsIsScienceoardByUserId, postLogSave } from "../../api/utils";
import { withSnackbar } from "notistack";

class SidenavContent extends Component {
  state = {
    data: [],
    open: false,
    isScienceBoard: false,
  };

  componentDidMount = async () => {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }

    const user = this.props.authUser;
    let result = {};
    let data = [];

    if (user.userRoles.some((role) => role.id === 1))
      result = await getAppFullAllApi();
    else result = await getAppFullByUserIdApi(user.id);

    if (result.status) {
      data = result.data;
    } else {
      ////console.log("result error",result.error);
      if (result.error.status === 403) {
        this.props.showAuthMessage(<IntlMessages id="error.sessionEnd" />);
        this.props.userSignOut();
        //    history.push("/bbs/error403");
      } else history.push("/bbs/error404");
    }

    // //console.log("geleb data ",data)
    this.setState({ data });


    const resultScienceBoard = await getOpinionsIsScienceoardByUserId(user.id);
    if (resultScienceBoard.status) {

      if (resultScienceBoard.data.length > 0) {
        this.setState({
          isScienceBoard: resultScienceBoard.data.length > 0 ? true : false
        })

        const message = <IntlMessages id="opinions.alert.message" />
        this.key = this.props.enqueueSnackbar(message, {
          variant: 'info',
          autoHideDuration: 10000,
          action: key => (

            <Fragment>
              <Button color="secondary" onClick={() => { history.push("/bbs/opinionsPage"); this.props.closeSnackbar(key); }}>
                <IntlMessages id="button.ok" />
              </Button>
              <Button color="secondary" onClick={() => { this.props.closeSnackbar(key) }}>
                <IntlMessages id="button.close" />
              </Button>
            </Fragment>
          ),
        });
      }

    }

  };

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  isRoleCheck = (roles, roleId) => {
    return roles.find((x) => x.id === roleId) !== undefined;
  };
  onClickItem = (item, user) => {
    /// window.location.replace(url);

    const data = {
      userId: user.id,
      pwd: user.pwd,
      roleId: item.detailList[0].roleId,
      first: 1,
      token: localStorage.getItem("userToken"),
    };
    //  //console.log("Bu Link Tıklandı",item)

    postLogSave({
      userId: data.userId,
      info: item.appAbbreviationName,
      appId: item.id,
      opType: 1,
      processId: 28
    })


    const appUrl = item.link;
    let url = `${appUrl}?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;
    ////console.log(url);
    window.location.replace(url);

    //   axios.post(item.detailList[0].link,data,{"headers": {
    //     'Access-Control-Allow-Origin': '*' ,
    //     'Access-Control-Allow-Methods':'HEAD, GET, POST, PUT, PATCH, DELETE',
    //     'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token',
    //     'Access-Control-Expose-Headers': 'Access-Token, Uid'

    // }});
  };
  handleRequestClose = (value) => {
    this.setState({ selectedValue: value, open: false });
  };
  render() {
    const userRoles = this.props.authUser.userRoles;
    const user = this.props.authUser;
    //  //console.log("------ userRoles ",userRoles)
    // //console.log("coordinatorMenuStatus ",  this.isRoleCheck(userRoles,1),this.props.authUser ,userRoles)

    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li
            style={{
              marginRight: "20px",
              marginLeft: "20px",
              paddingLeft: 0,
              paddingRight: 0,
              marginTop: "10px",
              paddingBottom: "10px",
              borderBottom: "solid 1px #343434",
              borderColor: "#ced4da",
            }}
          >
            <ul className="social-link mt-3 ml-3">
              <li style={{ padding: "0 3px" }}>
                <img
                  src={require("assets/images/ayk_logo.png")}
                  width="30px"
                  alt="Atatük Kültür, Dil ve Tarih Yüksek Kurumu"
                  title="Atatük Kültür, Dil ve Tarih Yüksek Kurumu"
                />
              </li>
              <li style={{ padding: "0 3px" }}>
                <img
                  src={require("assets/images/atam.png").default}
                  width="30px"
                  alt="Atatürk Araştırma Merkezi"
                  title="Atatürk Araştırma Merkezi"
                />
              </li>
              <li style={{ padding: "0 3px" }}>
                <img
                  src={require("assets/images/akm.png").default}
                  width="30px"
                  alt="Yüksek Kurum"
                  title="Atatürk Kültür Merkezi Başkanlığı"
                />
              </li>

              <li style={{ padding: "0 3px" }}>
                <img
                  src={require("assets/images/tdk.png").default}
                  width="30px"
                  alt="Yüksek Kurum"
                  title="Türk Dil Kurumu"
                />
              </li>
              <li style={{ padding: "0 3px" }}>
                <img
                  src={require("assets/images/ttk.png").default}
                  width="30px"
                  alt="Yüksek Kurum"
                  title="Türk Tarih Kurumu"
                />
              </li>
            </ul>
          </li>
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/bbs/mainPage">
              <i className="zmdi zmdi-home zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.mainPage" />{" "}
              </span>
            </NavLink>
          </li>
          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 70) || this.isRoleCheck(userRoles, 71) || this.isRoleCheck(userRoles, 72) || this.isRoleCheck(userRoles, 73) || this.isRoleCheck(userRoles, 74) || this.isRoleCheck(userRoles, 75) ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/archiveSearch">
                <i className="zmdi zmdi-search zmdi-hc-fw" />
                <span className="nav-text">Dijital Arşivlerde Ara </span>
              </NavLink>
            </li>
          ) : null}
          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 70) || this.isRoleCheck(userRoles, 71) ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/fileServer">
                <i className="zmdi zmdi-book zmdi-hc-fw" />
                <span className="nav-text">Dijital Kitap Arşivi </span>
              </NavLink>
            </li>
          ) : null}
          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 72) || this.isRoleCheck(userRoles, 73) || this.isRoleCheck(userRoles, 74) ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/imageArchive">
                <i className="zmdi zmdi-image zmdi-hc-fw" />
                <span className="nav-text">Dijital Görsel Arşivi </span>
              </NavLink>
            </li>
          ) : null}

          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 72) || this.isRoleCheck(userRoles, 73) || this.isRoleCheck(userRoles, 74) ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/videoArchive">
                <i className="zmdi zmdi-videocam zmdi-hc-fw" />
                <span className="nav-text">Dijital Video Arşivi </span>
              </NavLink>
            </li>
          ) : null}


          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 75) ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/documentArchive">
                <i className="zmdi zmdi-file zmdi-hc-fw" />
                <span className="nav-text">Dijital Belge Arşivi </span>
              </NavLink>
            </li>
          ) : null}
              {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 10)   ? (
            <li className="menu no-arrow">
              <NavLink to="/bbs/gifts">
                <i className="zmdi zmdi-mood zmdi-hc-fw" />
                <span className="nav-text">Armağan Yayın Talebi</span>
              </NavLink>
            </li>
          ) : null}
          {
            (this.state.isScienceBoard || this.isRoleCheck(userRoles, 1)) && <li className="menu no-arrow">
              <NavLink to="/bbs/opinionsPage">
                <i className="zmdi zmdi-help-outline zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.opinionsPage" />{" "}
                </span>
              </NavLink>
            </li>

          }

      

          {this.isRoleCheck(userRoles, 1) ? (
            <li className="nav-header">
              <IntlMessages id="sidebar.admin" />
            </li>
          ) : null}

          {this.isRoleCheck(userRoles, 1) || this.isRoleCheck(userRoles, 2) ? (
            <li className="menu collapse-box">
              {" "}
              <Button>
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.adminMenuTitle" />
                </span>
              </Button>
              <ul className="sub-menu">
                {this.isRoleCheck(userRoles, 1) && <li className="menu no-arrow">
                  <NavLink to="/bbs/users">
                    <i className="zmdi zmdi-account zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="pages.usersPage" />{" "}
                    </span>
                  </NavLink>
                </li>}
                <li className="menu no-arrow">
                  <NavLink to="/bbs/ourOwnInstitutionsUserPage">
                    <i className="zmdi zmdi-account zmdi-hc-fw" />
                    <span className="nav-text">
                      Kurum Kullanıcıları
                    </span>
                  </NavLink>
                </li>

                {this.isRoleCheck(userRoles, 1) && <li className="menu no-arrow">
                  <NavLink to="/bbs/roleManagePage">
                    <i className="zmdi zmdi-accounts-list zmdi-hc-fw" />
                    <span className="nav-text">
                      Rol Tanımları
                    </span>
                  </NavLink>
                </li>}

                {this.isRoleCheck(userRoles, 1) && <li className="menu no-arrow">
                  <NavLink to="/bbs/institutions">
                    <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="pages.insPage" />{" "}
                    </span>
                  </NavLink>
                </li>}
                {this.isRoleCheck(userRoles, 1) && <li key="ur2" className="menu no-arrow">
                  <NavLink to="/bbs/notification">
                    <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.notification" />{" "}
                    </span>
                  </NavLink>
                </li>}

                {/* <li className="menu no-arrow">
    <NavLink to="/bbs/notification">
      <i className="zmdi zmdi-notifications-active zmdi-hc-fw"/>
      <span className="nav-text"><IntlMessages id="sidebar.notification"/> </span>
    </NavLink>
  </li> */}
              </ul>
            </li>
          ) : null}
          {/* { this.isRoleCheck(userRoles, 1)
            ? [
                <li key="ur1" className="nav-header">
                  <IntlMessages id="sidebar.notification" />
                </li>,
                <li key="ur2" className="menu no-arrow">
                  <NavLink to="/bbs/notification">
                    <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.notification" />{" "}
                    </span>
                  </NavLink>
                </li>,
              ]
            : null} */}
          {this.isRoleCheck(userRoles, 7) ||
            this.isRoleCheck(userRoles, 8) ||
            this.isRoleCheck(userRoles, 1)
            ? [
              <li className="nav-header" key="keyabsis1">
                <IntlMessages id="sidebar.absis" />
              </li>,
              <li className="menu no-arrow" key="keyabsis2">
                {this.isRoleCheck(userRoles, 8) ||
                  this.isRoleCheck(userRoles, 1) ? (
                  <NavLink to="/bbs/absis">
                    <i className="zmdi zmdi-accounts-outline zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.absis.list" />{" "}
                    </span>
                  </NavLink>
                ) : null}

                {this.isRoleCheck(userRoles, 8) ||
                  this.isRoleCheck(userRoles, 1) ? (
                  <NavLink to="/bbs/absis/scienceBoard">
                    <i className="zmdi zmdi-accounts-outline zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="absis.scienceBoard" />{" "}
                    </span>
                  </NavLink>
                ) : null}

                {this.isRoleCheck(userRoles, 8) ||
                  this.isRoleCheck(userRoles, 1) ? (
                  <NavLink to="/bbs/absis/userSearch">
                    <i className="zmdi zmdi-search zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.absis.search" />{" "}
                    </span>
                  </NavLink>
                ) : null}

                {this.isRoleCheck(userRoles, 7) ||
                  this.isRoleCheck(userRoles, 1) ? (
                  <NavLink to={`/bbs/absis/userDetail/${user.id}`}>
                    <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.absis.my" />{" "}
                    </span>
                  </NavLink>
                ) : null}
              </li>,
            ]
            : null}

          <li className="nav-header" key="1">
            <IntlMessages id="sidebar.quickAccessMenu" />
          </li>

          <li className="menu collapse-box" key="2">
            {" "}
            <Button>
              <i className="zmdi zmdi-border-color zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.applications" />
              </span>
            </Button>
            <ul className="sub-menu">
              {this.state.data.map((dataItem, index) =>
                dataItem.detailList.length === 1 ||
                  dataItem.multipleInput === 0 ? (
                  <li
                    className="menu no-arrow"
                    key={index}
                    onClick={() => this.onClickItem(dataItem, user)}
                  >
                    <NavLink to="/">
                      <i className={`zmdi zmdi-${dataItem.icon} zmdi-hc-fw`} />
                      <span className="nav-text">
                        {dataItem.appAbbreviationName} {dataItem.appNameDetail}{" "}
                      </span>
                    </NavLink>
                  </li>
                ) : null
              )}
            </ul>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { authUser } = auth;
  const { locale } = settings;
  return { authUser, locale };
};

export default withRouter(
  withSnackbar(connect(
    mapStateToProps,
    { userSignOut, showAuthMessage }
  )(SidenavContent))
);
