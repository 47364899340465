import axios from 'axios';
//import config from '../../config';
import {envConfig as config} from '../../environment';


axios.interceptors.request.use(function (config) {
   const token = localStorage.getItem("userToken");
   config.headers.Authorization =  token;

   return config;
});

export const postLoginUserApi = async(user)=>{
   const url = `${config.apiUrl}/v1/auth/login`;
   return await axios.post(url,user)
   .then(response => response.data)
}
export const postLoginEdevletUserApi = async(user)=>{
   const url = `${config.apiUrl}/v1/auth/loginEdevlet`;
   return await axios.post(url,user)
   .then(response => response.data)
}
export const postCreateUserApi = async(user)=>{
   const url = `${config.apiUrl}/v1/auth/signUp`;
   return await axios.post(url,user,{})
   .then(response => response.data)
}
export const postCreateUserEdevletApi = async(user)=>{
   const url = `${config.apiUrl}/v1/auth/signUpEdevlet`;
   return await axios.post(url,user)
   .then(response => response.data)
}
export const postForgotMyPasswordUserApi = async(user)=>{
   const url = `${config.apiUrl}/v1/auth/forgotMyPassword`;
   return await axios.post(url,user)
   .then(response => response.data)
}

export const postResetPasswordUserApi = async(resetPaswordObject)=>{
   const url = `${config.apiUrl}/v1/auth/resetPasswordUser`;
   return await axios.post(url,resetPaswordObject)
   .then(response => response.data)
}
export const postActivationUserApi = async(activationObject)=>{
   const url = `${config.apiUrl}/v1/auth/activeUser`;
   return await axios.post(url,activationObject)
   .then(response => response.data)
}
export const postLoginEdevlet2Api = async(obj)=>{
   const url = `${config.apiUrl}/v1/auth/loginEdevlet2`;
   return await axios.post(url,obj)
   .then(response => response.data)
} 