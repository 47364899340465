const languageData = [
  
  {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Türkçe',
    icon: 'tr'
  },
   {
     languageId: 'english',
     locale: 'en',
     name: 'English',
     icon: 'us'
   },
   {
    languageId: 'russian',
    locale: 'ru',
    name: 'Русский',
    icon: 'ru'
  },
  
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   icon: 'fr'
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it'
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa'
  // }
  

];
export default languageData;
