import {
  
    SET_ABSIS_USER,

} from "constants/ActionTypes"; 

const INIT_STATE = {
    user: null,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ABSIS_USER: {
            return {
                ...state,
                user: action.payload.user,
      
            }
        }
        default:
            return state;
    }
}
