import MomentUtils from '@date-io/moment';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { showAuthMessage, userSignOut } from "actions/Auth";
import MainApp from 'app/index';
import "assets/vendors/style";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';
import IntlMessages from 'util/IntlMessages';
import RTL from 'util/RTL';
import { setInitRedirectUrl, setInitUrl } from '../actions/Auth';
//import {loadReCaptcha } from "react-google-recaptcha";
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { envConfig as config } from '../environment';
import AppLocale from '../lngProvider';
import ActiveUser from './ActiveUser';
import ForgotMyPassword from './ForgotMyPassword';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import SignInEdevlet from './SignInEdevlet';
import SignInEdevletDestek from './SignInEdevletDestek';
import SignInEdevletProje from './SignInEdevletProje';
import SignUp from './SignUp';
import amberTheme from './themes/amberTheme';
import blueTheme from './themes/blueTheme';
import cyanTheme from './themes/cyanTheme';
import greenTheme from './themes/greenTheme';
import indigoTheme from './themes/indigoTheme';
import orangeTheme from './themes/orangeTheme';
import pinkTheme from './themes/pinkTheme';
import purpleTheme from './themes/purpleTheme';
import { SnackbarProvider } from 'notistack';
import SignInEdevletYaysis from './SignInEdevletYaysis';
import SignInEdevletAbsis from './SignInEdevletAbsis';
import SignInEdevletBilsis from './SignInEdevletBilsis';


const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        :
         <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />
      }
  />;

class App extends Component {

  state = {
    error:false,errorMessage:"message",
  }

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default : createMuiTheme(blueTheme);
    } 
    return applyTheme;
  }
 
  urlParams = (url,name)=>{
    // eslint-disable-next-line
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
  }

  render() {
    const {match, location, locale, authUser, initURL, isDirectionRTL} = this.props;

    let applyTheme = createMuiTheme(blueTheme);
    
  //  //console.log("redirectURL ",redirectUrl);
   // //console.log("initURL ",initURL);

    const paramsString = location.search;
    
    const status = this.urlParams(paramsString,'status');
    const message = this.urlParams(paramsString,'message');
    const errorCode = this.urlParams(paramsString,'code');

    if(errorCode==="403")
    {
      this.props.showAuthMessage(<IntlMessages id="error.sessionEnd" />);
      this.props.userSignOut();
    }
    else if(errorCode==="401")
    {
      this.props.showAuthMessage(<IntlMessages id="error.sessionEnd.401" />);
      this.props.userSignOut();
    }
    else if(status === "false")
    {
        this.setState({error:true,errorMessage:message,errorCode});
    }



  // if ( (redirectUrl !== null ) && authUser !== null ) {
  //     return ( <Redirect to={redirectUrl.url +'?'+ redirectUrl.paramKey + '='+ redirectUrl.paramValue}/> );
  // }

    if (location.pathname === '/') {

       if ( (initURL === '/resetPassword/' || initURL === '/resetPassword')  ) {
        return ( <Redirect to={`/resetPassword`}/> );
      }
      else if (authUser === null) {
        return ( <Redirect to={`/signin`}/> );
      }
      else if (initURL === '' || initURL === '/' || initURL === '/index' || initURL === '/gate' || initURL === '/gate/'
       || initURL === '/signin' ||  initURL === '/signin/' || initURL === `${process.env.PUBLIC_URL}/signin`) {
        return ( <Redirect to={'/bbs/mainPage'}/> );
      }
      else if ( (initURL === '/bbs/' || initURL === '/bbs' || initURL === '/index') && authUser !== null ) {
          return ( <Redirect to={`/bbs/mainPage`}/> );
      }
       else 
       {
        return ( <Redirect to={initURL}/> ); 
      }

 

    }
  



    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>

        <MuiPickersUtilsProvider utils={MomentUtils}>

          <IntlProvider 
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
                              <SnackbarProvider maxSnack={2} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}>

            <RTL>
              <div className="app-main">

                <Switch>
                  <RestrictedRoute path={`${match.url}bbs`} authUser={authUser}
                                   component={MainApp}/>
                  <Route path={`/signin`} component={SignIn}/>
                  <Route path={`/index`} component={SignIn}/>
                  <Route path={`/gate`} component={SignIn}/>
                  <Route path={`/gate/`} component={SignIn}/>
                  <Route path={`/signUp`} component={SignUp}/>
                  <Route path='/forgotMyPassword' component={ForgotMyPassword}/>
                  <Route path='/resetPassword' component={ResetPassword}/>
                  <Route path='/activationUser' component={ActiveUser}/>
                  <Route path='/login_edevlet'  component={SignInEdevlet}/>
                  <Route path='/login_edevlet_proje' component={SignInEdevletProje}/>
                  <Route path='/login_edevlet_destek' component={SignInEdevletDestek}/> 
                  <Route path='/login_edevlet_yaysis' component={SignInEdevletYaysis}/>
                  <Route path='/login_edevlet_absis' component={SignInEdevletAbsis}/>
                  <Route path='/login_edevlet_bilsis' component={SignInEdevletBilsis}/>

                  <Route path='/bilsis/'  component={() => { 
                              
                              const {location, authUser, initURL } = this.props;
                              ////console.log("gdata props ",this.props);
                            //  //console.log("gdata initURL ",this.props.initURL);

                              if(authUser === null ){
                                const redirectUrl = '/bilsis/?a='+this.urlParams(location.search,"a");
                             
                                this.props.setInitRedirectUrl(redirectUrl)
                                return ( <Redirect to={`/signin`}/> ); 
                              }
                              else{
                                const data = {
                                  userId : authUser.id,
                                  pwd : authUser.pwd,
                                  first: 1,
                                  roleId : 40,
                                  token : localStorage.getItem("userToken"),
                                  url:initURL,
                                  aid: this.urlParams(location.search,"a"),
                                };
                      
                             //   let url = `${config.bilsisUrl}/bys/?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr&url=${data.url}&aid=${data.aid}`;
                                let url = `${config.bilsisUrl}/bys/?t=${data.token}?${data.roleId}?${this.props.locale.locale}?${data.url}?${data.aid}`;

                               window.location.replace(url); 
  
                                    return null;
                              }

                            
                              }} />

                  <Route path='/bilsis/sendSubmission'  component={() => { 
                              
                              const {location, authUser, initURL } = this.props;
                              ////console.log("gdata props ",this.props);
                              //console.log("gdata initURL ",this.props.initURL);

                              if(authUser === null ){
                                const redirectUrl = '/bilsis/sendSubmission?a='+this.urlParams(location.search,"a");
                             
                                this.props.setInitRedirectUrl(redirectUrl)
                                return ( <Redirect to={`/signin`}/> ); 
                              }
                              else{
                                const data = {
                                  userId : authUser.id,
                                  pwd : authUser.pwd,
                                  first: 1,
                                  roleId : 40,
                                  token : localStorage.getItem("userToken"),
                                  url:initURL,
                                  aid: this.urlParams(location.search,"a"),
                                };
                               // //console.log("bbs gdata didmount 2 ", data );
  
                               // const appUrl = "http://localhost:3001";
                             //   let url = `${config.bilsisUrl}/bilsis/sendSubmission?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr&url=${data.url}&aid=${data.aid}`;
                                let url = `${config.bilsisUrl}/bilsis/sendSubmission?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;

                               window.location.replace(url); 
  
                                    return null;
                              }

                            
                              }} />
   <Route  path={"/kurulsis/"}  component={() => { 
                              
                              const {location, authUser, initURL } = this.props;
                             ////console.log("gdata props ",this.props);
                             //console.log("gdata initURL ",this.props.initURL);

                             if(authUser === null){
                                const redirectUrl = "/kurulsis " //+'?p='+this.urlParams(location.search,"p");
                             
                                this.props.setInitRedirectUrl(redirectUrl)
                                return ( <Redirect to={`/signin`}/> ); 
                             }
                              else{
                                const data = {
                                  userId : authUser.id,
                                  pwd : authUser.pwd,
                                  first: 1,
                                  roleId : 40,
                                  token : localStorage.getItem("userToken"),
                                  url:initURL,
                                  aid: this.urlParams(location.search,"p"),
                                };

                          
                             //   let url = `${config.kurulsisUrl}?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr&url=${data.url}&aid=${data.aid}`;
                         
                                let url = `${config.kurulsisUrl}?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;

                                window.location.replace(url); 
                                 return null;
                              }

                            
                              }} />  

                   <Route path='/yaysis/'  component={() => { 
                              
                              const {location, authUser, initURL } = this.props;
                              ////console.log("gdata props ",this.props);
                              //console.log("gdata initURL ",this.props.initURL);

                              if(authUser === null ){
                                const redirectUrl = '/yaysis?a='+this.urlParams(location.search,"a");
                             
                                this.props.setInitRedirectUrl(redirectUrl)
                                return ( <Redirect to={`/signin`}/> ); 
                              }
                              else{
                                const data = {
                                  userId : authUser.id,
                                  pwd : authUser.pwd,
                                  first: 1,
                                  roleId : 40,
                                  token : localStorage.getItem("userToken"),
                                  url:initURL,
                                  aid: this.urlParams(location.search,"a"),
                                };
                              //  //console.log("bbs gdata didmount 2 ", data );
  
                               // const appUrl = "http://localhost:3001";
                                //   let url = `${config.yaysisUrl}/yts/mainPage?userId=${data.userId}&pwd=${data.pwd}&first=1&roleId=${data.roleId}&token=${data.token}&lng=tr&url=${data.url}&aid=${data.aid}`;
                                let url = `${config.yaysisUrl}/yts/mainPage?t=${data.token}?${data.roleId}?tr?${data.url}?${data.aid}`;

                               
                               window.location.replace(url); 
  
                                    return null;
                              }

                            
                              }} /> 
                              

                  <Route
                    component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>

                <SweetAlert show={this.state.error}
                  warning
                  confirmBtnText={<IntlMessages id="button.ok"/>}
                  confirmBtnBsStyle="danger"
                  title={ this.state.errorCode==="403" ? <IntlMessages id="error.sessionEnd" /> : this.state.errorCode==="401" ? <IntlMessages id="error.sessionEnd.401" /> :  this.state.errorMessage}
                  onConfirm={()=>{
                    if(this.state.errorCode==="403")
                    {
                      this.props.showAuthMessage(<IntlMessages id="error.sessionEnd" />);
                      this.props.userSignOut();
                    }
                    if(this.state.errorCode==="401")
                    {
                      this.props.showAuthMessage(<IntlMessages id="error.sessionEnd.401" />);
                      this.props.userSignOut();
                    }
                    this.setState({error:false,errorMessage:'',errorCode:''})
             //       window.location.replace("/");
                  }}
              >
        </SweetAlert> 

              </div>
            </RTL>
            </SnackbarProvider>

          </IntlProvider> 
        </MuiPickersUtilsProvider>

      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  ////console.log("Gelen auth : " , auth)
  const {themeColor,sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {authUser, initURL , userName , redirectUrl} = auth;
  return {themeColor,sideNavColor, isDarkTheme: darkTheme,locale, isDirectionRTL, authUser, initURL, userName, redirectUrl}
};

export default connect(mapStateToProps, {setInitUrl,setInitRedirectUrl,userSignOut, showAuthMessage })(App);

