import axios from 'axios';
//import config from '../../config';
import {envConfig as config} from '../../environment';


export const postNotificationSend = (notificationObj)=>{
   const url = `${config.apiUrl}/v1/util/notificationSend`;
   return axios.post(url,notificationObj)
   .then(response => response.data);
}
export const postLogSave = (log)=>{
   const url = `${config.apiUrl}/v1/log`;
   return axios.post(url,log)
   .then(response => response.data);
}

export const postOpinionsSave = (Opinions)=>{
   const url = `${config.apiUrl}/v1/util/opinionsSave`;
   return axios.post(url,Opinions)
   .then(response => response.data);
}

export const getSMSBalance = ()=>{
   const url = `${config.apiUrl}/v1/util/getSMSBalance`;
   return axios.get(url)
   .then(response => response.data);
}


export const getOpinionsByInst = (inst)=>{
   const url = `${config.apiUrl}/v1/util/opinions/${inst}`;
   return axios.get(url)
   .then(response => response.data);
}

export const getOpinionsIsScienceoardByUserId = (userId)=>{
   const url = `${config.apiUrl}/v1/util/opinions/isScienceBoard/${userId}`;
   return axios.get(url)
   .then(response => response.data);
}